import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { create } from "zustand";
import { AuthUser } from "@/core";
import { serviceHost } from "@/services";

enum QueryKeys {
  signInAnonymously = "signInAnonymously",
  signInWithCustomToken = "signInWithCustomToken",
  signInWithEmailAndPassword = "signInWithEmailAndPassword",
  updatePassword = "updatePassword",
  updateEmail = "updateEmail",
  updateProfile = "updateProfile",
  sendPasswordResetEmail = "sendPasswordResetEmail",
  signOut = "signOut",
}

export interface AuthStore {
  authUser: AuthUser | null;
  setAuthUser: (authUser: AuthUser | null) => void;
}

const authenticationService = serviceHost.getAuthenticationService();

export const useAuthStore = create<AuthStore>((set) => ({
  authUser: null,
  setAuthUser: (authUser) => set({ authUser }),
}));

export const useAuthUser = () => {
  return useAuthStore((state) => state.authUser);
};

export const useSignInAnonymously = () => {
  return useMutation({
    mutationKey: [QueryKeys.signInAnonymously],
    mutationFn: authenticationService.signInAnonymously,
  });
};

export const useOnUserStateChange = () => {
  const setAuthUser = useAuthStore((state) => state.setAuthUser);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    return authenticationService.onUserStateChanged((user) => {
      setAuthUser(user);
      setIsLoading(false);
    });
  }, [setAuthUser]);

  return isLoading;
};

export const useSignInWithCustomToken = () => {
  return useMutation({
    mutationKey: [QueryKeys.signInWithCustomToken],
    mutationFn: authenticationService.signInWithCustomToken,
  });
};

export const useSignInWithEmailAndPassword = () => {
  return useMutation({
    mutationKey: ["signInWithEmailAndPassword", "credentials"],
    mutationFn: authenticationService.signInWithEmailAndPassword,
  });
};

export const useUpdatePassword = () => {
  return useMutation({
    mutationKey: [QueryKeys.updatePassword],
    mutationFn: authenticationService.updatePassword,
  });
};

export const useUpdateEmail = () => {
  return useMutation({
    mutationKey: [QueryKeys.updateEmail],
    mutationFn: authenticationService.updateEmail,
  });
};

export const useUpdateProfile = () => {
  return useMutation({
    mutationKey: [QueryKeys.updateProfile],
    mutationFn: authenticationService.updateProfile,
  });
};

export const useSendPasswordResetEmail = () => {
  return useMutation({
    mutationKey: [QueryKeys.sendPasswordResetEmail],
    mutationFn: authenticationService.sendPasswordResetEmail,
  });
};

export const useSignOut = () => {
  return useMutation({
    mutationKey: [QueryKeys.signOut],
    mutationFn: authenticationService.signOut,
  });
};
