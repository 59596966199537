import {z} from 'zod';

export const customerDetailsSchema = z.object({
  name: z.string(),
  phoneNumber: z.string(),
  email: z.string(),
});

export const partialCustomerDetailsSchema = customerDetailsSchema.partial();

export type PartialCustomerDetails = z.infer<typeof partialCustomerDetailsSchema>;

export type CustomerDetails = z.infer<typeof customerDetailsSchema>;
