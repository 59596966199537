import { FC } from "react";
import { Address } from "@/core";
import { EmptyState } from "@/components/shared/EmptyState.tsx";
import { useTranslation } from "react-i18next";
interface ShippingAddressProps {
  address: Address;
}
export const ShippingAddress: FC<ShippingAddressProps> = ({ address }) => {
  const { t } = useTranslation("translation");

  const isEmpty =
    !address.address &&
    !address.postalCode &&
    !address.city &&
    !address.country;
  if (isEmpty) {
    return (
      <EmptyState
        title={t("noAddressFound")}
        message={t("noAddressFoundMessage")}
      />
    );
  }
  return (
    <div className={"flex flex-col gap-4 py-2"}>
      <div className={"flex space-x-2 divide-x"}>
        <span className={"font-light"}>{address.address}</span>
        <span className={"font-light pl-2"}>{address.postalCode}</span>
      </div>
      <div className={"flex gap-2"}>
        <span className={"font-light"}>{address.city}</span>
        <span className={"font-light"}>{address.country}</span>
      </div>
    </div>
  );
};
