import {
  CustomerPortalCancelSubscriptionPayload,
  NextDeliveryDatePayload,
  SubscriptionService,
  UpdateSubscriptionCapsulesAndQuantityPackagePayload,
  UpdateSubscriptionIntervalPayload
} from '@/core/ports/services/subscription-service.ts';
import { httpsCallable } from '@firebase/functions';
import { firebase } from '@/infrastructure';

export const subscriptionService: SubscriptionService = {
  async updateSubscriptionCapsulesAndQuantityPackage(payload) {
    await httpsCallable<
      UpdateSubscriptionCapsulesAndQuantityPackagePayload,
      void
    >(
      firebase.functions,
      "v2CustomerPortalUpdateSubscriptionCapsules",
    )(payload);
  },
  async skipSubscription() {
    await httpsCallable<void>(
      firebase.functions,
      "v2CustomerPortalSkipSubscription",
    )();
  },
  async unSkipSubscription() {
    await httpsCallable<void>(
      firebase.functions,
      "v2CustomerPortalUnSkipSubscription",
    )();
  },
  async updateNextDeliveryDate(payload) {
    await httpsCallable<NextDeliveryDatePayload, void>(
      firebase.functions,
      "v2CustomerPortalUpdateSubscriptionNextBillingDate",
    )(payload);
  },
  async cancelSubscription (payload) {
    await httpsCallable<CustomerPortalCancelSubscriptionPayload, void>(
      firebase.functions,
      'v2CustomerPortalCancelSubscription'
    )(payload);
  },
  async reactivateSubscription () {
    await httpsCallable<void>(
      firebase.functions,
      'v2CustomerPortalReactivateSubscription'
    )();
  },
  async updateSubscriptionInterval (payload) {
    await httpsCallable<UpdateSubscriptionIntervalPayload, void>(
      firebase.functions,
      'v2CustomerPortalUpdateSubscriptionInterval'
    )(payload);
  },
  async billNow () {
    await httpsCallable<void>(
      firebase.functions,
      'v2CustomerPortalBillNow'
    )();
  }
};
