import { FC } from "react";

import { Icons } from "@/components/ui/icons.tsx";

import { CustomerData } from "@/core";

interface ProfileDetailsProps {
  customer: CustomerData;
}

export const CustomerProfileDetails: FC<ProfileDetailsProps> = ({
  customer,
}) => {
  return (
    <div className={"grid gap-2"}>
      <div className={"flex items-center gap-2"}>
        <Icons.user className={"w-5 h-5"} />
        <p className={"font-light"}>
          {customer.firstName} {customer.lastName}
        </p>
      </div>
      <div className={"flex items-center gap-2"}>
        <Icons.email className={"w-5 h-5"} />
        <p className={"font-light"}>{customer.email}</p>
      </div>
      <div className={"flex items-center gap-2"}>
        <Icons.phone className={"w-5 h-5"} />
        <p className={"font-light"}>{customer.phone || "N/A"}</p>
      </div>
    </div>
  );
};
