import { Outlet, useNavigate } from "react-router-dom";
import { FC } from "react";

import { SidebarNav } from "@/components/shared/sidebar-nav.tsx";
import { useAuthUser } from "@/hooks";
import { RoutePath } from "@/routes/constants/route-paths.ts";

export const InternalLayout: FC = () => {
  const authUser = useAuthUser();
  const navigate = useNavigate();

  if (!authUser) {
    navigate(RoutePath.LOGIN);
  }
  return (
    <div className={"lg:grid lg:grid-cols-8 lg:gap-8"}>
      <div className={"lg:col-span-2"}>
        <SidebarNav />
      </div>
      <div className={"lg:col-span-6"}>
        <Outlet />
      </div>
    </div>
  );
};
