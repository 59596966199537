import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardFooter, CardHeader, CardTitle } from '@/components/ui/card.tsx';

import { SkipSubscription } from '@/pages/subscription-manage/components/skip-subscription.tsx';
import { CancelSubscription } from '@/pages/subscription-manage/components/cancel-subscription.tsx';
import { UnskipSubscription } from '@/pages/subscription-manage/components/unskip-subscription.tsx';
import { ReActivateSubscription } from '@/pages/subscription-manage/components/re-activate-subscription.tsx';

import { Subscription, SubscriptionStatus } from '@/core';

interface CancelOrPauseProps {
  subscription: Subscription;
}

export const CancelOrSkip: FC<CancelOrPauseProps> = (props) => {
  const { subscription } = props;
  const { t } = useTranslation("translation");

  const isSkipped = useMemo(() => {
    return subscription.status === SubscriptionStatus.SKIPPED;
  }, [subscription.status]);

  const notFailedNorCancelled = useMemo(() => {
    return subscription.status !== SubscriptionStatus.FAILED && subscription.status !== SubscriptionStatus.CANCELLED;
  }, [subscription.status]);


  return (
    <Card className={"flex flex-col justify-between"}>
      <CardHeader>
        <CardTitle>
          <p className={"font-semibold"}>{t("cancelOrPause")}</p>
        </CardTitle>
      </CardHeader>
      <CardFooter
        className={
          (subscription.status === SubscriptionStatus.CANCELLED || subscription.status === SubscriptionStatus.FAILED) ?
            "grid grid-cols-1 gap-6" :
            "grid grid-rows-2 gap-6 justify-end"
          }
      >
        {notFailedNorCancelled && !isSkipped && (<SkipSubscription subscription={subscription} />)}
        {notFailedNorCancelled && isSkipped && (<UnskipSubscription subscription={subscription} />)}
        {notFailedNorCancelled && (<CancelSubscription />)}

        {(subscription.status === SubscriptionStatus.CANCELLED || subscription.status === SubscriptionStatus.FAILED) &&
          (<ReActivateSubscription/>)
        }
      </CardFooter>
    </Card>
  );
};
