import { useTranslation } from 'react-i18next';

import { EmptyState } from '@/components/shared/EmptyState.tsx';
import { ErrorState } from '@/components/shared/ErrorState.tsx';
import { LoadingState } from '@/components/shared/LoadingState.tsx';

import { OrderHistory } from '@/pages/subscription-orders/components/order-history.tsx';

import { useGetOrderHistory } from '@/hooks';
import { useAuthUser } from '@/hooks/service-hooks/auth/use-auth.ts';
import { Order } from '@/core';

export function SubscriptionOrdersPage() {
  const { t } = useTranslation("translation");
  const authUser = useAuthUser();

  const {
    data: orderHistory,
    isLoading: isOrderHistoryLoading,
    error: orderHistoryError,
  } = useGetOrderHistory({
    id: authUser?.uid || "",
  });


  if (isOrderHistoryLoading) {
    return <LoadingState />;
  }

  if (orderHistoryError) {
    console.log("orderHistoryError", orderHistoryError);
    return <ErrorState />;
  }

  if (!orderHistory) {
    console.log("orderHistory", orderHistory);
    return (
      <EmptyState
        title={t("noOrdersFound")}
        message={t("noOrdersFoundDescription")}
      />
    );
  }

  const ordersByMonth = orderHistory.reduce((acc, order) => {
    const month = new Date(order.createdAt).toLocaleString('default', {
      month: 'long'
    });

    if (!acc[month]) {
      acc[month] = [];
    }

    acc[month].push(order);

    return acc;
  }, {} as { [key: string]: Order[] });

  return (
    <div className={' lg:py-0'}>
      <div className={"space-y-12"}>
        {Object.entries(ordersByMonth).length === 0 && (
          <EmptyState
            title={t("noOrdersFound")}
            message={t("noOrdersFoundDescription")}
          />
        )}

        {Object.entries(ordersByMonth).map(([month, orders], index) => (
          <div key={index}>
            <h2 className={'font-extralight text-4xl mb-6'}>
              {t(month.toLowerCase())}
            </h2>
            {orders.map((order, index) => (
              <div className={'my-6'}>
                <OrderHistory
                  key={index}
                  order={order}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
