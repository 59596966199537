import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetCustomer } from '@/hooks';
import { useAuthUser } from '@/hooks/service-hooks/auth/use-auth.ts';

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle
} from '@/components/ui/card.tsx';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog.tsx';
import { Badge } from '@/components/ui/badge.tsx';
import { Button } from '@/components/ui/button.tsx';
import { ErrorState } from '@/components/shared/ErrorState.tsx';
import { EmptyState } from '@/components/shared/EmptyState.tsx';
import { LoadingState } from '@/components/shared/LoadingState.tsx';
import { CustomerProfileForm } from '@/components/forms/customer-profile-form.tsx';

import { CustomerProfileDetails } from '@/pages/subscription-profile/components/CustomerProfileDetails.tsx';

import { CustomerData } from '@/core';

export function SubscriptionProfilePage () {
  const { t } = useTranslation();
  const authUser = useAuthUser();

  const {
    data: customer,
    isLoading,
    error
  } = useGetCustomer({
    id: authUser?.uid || ''
  });

  if (error) {
    console.error('error', error);
    return <ErrorState/>;
  }

  if (isLoading) {
    return <LoadingState/>;
  }

  if (!customer) {
    return <EmptyState/>;
  }

  return (
    <div className={'px-4 py-12 lg:py-0'}>
      <div className={'flex justify-between'}>
        <div className={'grid gap-1 mb-6'}>
          <h3 className={'text-4xl font-light'}>
            {t('myProfile')}
          </h3>
          <p className={'font-extralight'}>
            {t('membershipNumber')}:{' '}
            <Badge variant={'secondary'}>{customer.id}</Badge>
          </p>
        </div>
      </div>

      <Card>
        <CardHeader>
          <CardTitle className={'font-semibold text-2xl pb-6'}>
            {t('myDetails')}
          </CardTitle>
        </CardHeader>
        <CardContent>
          <CustomerProfileDetails customer={customer}/>
        </CardContent>
        <CardFooter>
          <EditProfileDialog customer={customer}/>
        </CardFooter>
      </Card>
    </div>
  );
}

interface EditProfileDialogProps {
  customer: CustomerData;
}

const EditProfileDialog: FC<EditProfileDialogProps> = ({ customer }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean | undefined>(undefined);
  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
      }}
    >
      <DialogTrigger asChild>
        <Button>{t('edit')}</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className={'flex justify-center mb-6'}>
            {t('editProfile')}
          </DialogTitle>
          <div>
            <CustomerProfileForm
              closeDialog={() => setOpen(false)}
              defaultValues={customer}
            />
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
