import { DiscountCode, SubscriptionData } from '@/core';

export function calculateTotalSubscriptionPrice(subscription: SubscriptionData, discount: DiscountCode | null | undefined) {
  if(!subscription) {
    return 0;
  }

  if(!discount) {
    return subscription.subscriptionProduct.price.amount;
  }

  switch (discount.amountType){
    case 'percentage': {
      return subscription.subscriptionProduct.price.amount - (subscription.subscriptionProduct.price.amount * discount.amount / 100);
    }
    case 'fixed': {
      return subscription.subscriptionProduct.price.amount - discount.amount;
    }
    default: {
      return subscription.subscriptionProduct.price.amount;
    }
  }
}
