import { serviceHost } from '@/services';
import { repositoryHost } from '@/repositories';
import { useMutation, useQuery } from '@tanstack/react-query';
import { IDOnlyPayload, UpdatePayload } from '@/core/ports/repositories/utilities.ts';
import { CustomerData, DeliveryAddress } from '@/core';


const databaseService = serviceHost.getDatabaseService();

const customerRepository = repositoryHost.getCustomerRepository(databaseService);
const deliveryAddressRepository = repositoryHost.getDeliveryAddressRepository(databaseService);

export const useGetCustomer = (payload: IDOnlyPayload) => {
  return useQuery({
    queryKey: ['customer'],
    queryFn: () => {
      return customerRepository.getCustomer(payload);
    }
  });
};

export const useUpdateCustomerDetails = () => {
  return useMutation({
    mutationKey: ['customer', 'mutation', 'update-details'],
    mutationFn: (payload: UpdatePayload<DeliveryAddress>) => {
      return deliveryAddressRepository.update(payload);
    }
  });
}

// TEST FUNCTIONALITY: DEVELOPMENT ONLY
export const useSetCustomer = () => {
  return useMutation({
    mutationKey: ['customer', 'mutation', 'set'],
    mutationFn: (payload: UpdatePayload<CustomerData>) => {
      return customerRepository.setCustomer(payload);
    }
  });
};
