import { useTranslation } from 'react-i18next';
import { FC, useCallback, useEffect, useState } from 'react';

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog.tsx';
import { Icons } from '@/components/ui/icons.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useToast } from '@/components/ui/use-toast.ts';

import { useReActivateSubscription } from '@/hooks';

export const ReActivateSubscription: FC = () => {
  const { t } = useTranslation('translation');
  const { toast } = useToast();

  const [isOpen, setIsOpen] = useState<boolean | undefined>();

  const reActivateSubscription = useReActivateSubscription();

  const onSubmit = useCallback(() => {
    reActivateSubscription.mutate();
  }, [reActivateSubscription]);

  useEffect(() => {
    if (reActivateSubscription.isSuccess) {
      toast({
        title: t('subscriptionUpdated')
      });

      setIsOpen(false);
    }
  }, [toast, reActivateSubscription.isSuccess, t]);

  useEffect(() => {
    if (reActivateSubscription.isError) {
      toast({
        title: t('subscriptionUpdateFailed')
      });
    }
  }, [reActivateSubscription.isError, t, toast]);

  return (
    <AlertDialog open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <AlertDialogTrigger asChild>
        <Button variant="default" className={'min-w-36'}>{t('reactivateSubscription')}</Button>
      </AlertDialogTrigger>

      <AlertDialogContent className={'lg:max-w-3xl max-w-[90vw] max-h-[70vh] rounded-lg'}>
        <AlertDialogHeader>
          <AlertDialogTitle className={'text-center'}>{t('reactivateSubscription')}</AlertDialogTitle>
          <AlertDialogDescription className={'text-center'}>{t('areYouSureReActivateYourSubscription')}</AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter className={'mt-10'}>
          <AlertDialogCancel asChild>
            <Button onClick={() => setIsOpen(false)} variant="outline">
              {t('back')}
            </Button>
          </AlertDialogCancel>


          <Button
            disabled={reActivateSubscription.isPending}
            onClick={onSubmit}
          >
            {reActivateSubscription.isPending && <Icons.loading />}
            {t('reactivateSubscription')}
          </Button>
        </AlertDialogFooter>

      </AlertDialogContent>
    </AlertDialog>
  );
};
