import { FC } from "react";
import { Link } from "react-router-dom";
import { Icons } from "@/components/ui/icons.tsx";
import { Button } from "@/components/ui/button.tsx";

interface FooterProps {}

interface FooterItems {
  href: string;
  label: string;
}

// const footerItems: FooterItems[] = [
//   {
//     href: "https://coffydoor.com/",
//     label: "Subscribe",
//   },
//   {
//     href: "https://coffydoor.com/account/login",
//     label: "Shop Now",
//   },
//   {
//     href: "https://coffydoor.com/account/register",
//     label: "Contact us",
//   },
//   {
//     href: "https://coffydoor.com/account",
//     label: "About us",
//   },
//   {
//     href: "https://coffydoor.com/account",
//     label: "FAQ",
//   },
// ];

export const Footer: FC<FooterProps> = () => {
  return (
    <footer className={"bg-black flex justify-center flex-col py-8 mt-12 lg:mb-0"}>
      <div className={"flex justify-center px-2 py-2 text-white"}>
        <Icons.logo />
      </div>

      {/*<div className={"grid grid-cols-2 pt-4  sm:flex justify-center "}>*/}
      {/*  {footerItems.map((item, index) => (*/}
      {/*    <FooterItem key={index} href={item.href} label={item.label} />*/}
      {/*  ))}*/}
      {/*</div>*/}

      {/*<div*/}
      {/*  className={*/}
      {/*    "flex justify-center text-sm  gap-2 text-muted-foreground py-4"*/}
      {/*  }*/}
      {/*>*/}
      {/*  <span>Contact us on</span>*/}
      {/*  <a className={"underline text-background"} href={`mailto:${email}`}>*/}
      {/*    {email}*/}
      {/*  </a>*/}
      {/*</div>*/}

      {/*<div className={"flex justify-center text-muted-foreground gap-6 py-5"}>*/}
      {/*  <div className={"w-5 h-5"}>*/}
      {/*    <Icons.facebook />*/}
      {/*  </div>*/}
      {/*  <div className={"w-5 h-5"}>*/}
      {/*    <Icons.instagram />*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className={"flex justify-center text-muted-foreground mt-5 gap-6"}>
        <div className={"w-5 h-5"}>
          <Icons.klarna />
        </div>
        <div className={"w-5 h-5"}>
          <Icons.paypal />
        </div>
        <div className={"w-5 h-5"}>
          <Icons.visa />
        </div>
        <div className={"w-5 h-5"}>
          <Icons.masterCard />
        </div>
        <div className={"h-5 w-5"}>
          <Icons.amex />
        </div>
      </div>
      {/*<div*/}
      {/*  className={*/}
      {/*    "flex text-center sm:flex items-center justify-center gap-6 text-muted-foreground mt-4"*/}
      {/*  }*/}
      {/*>*/}
      {/*  <p className={"text-xs"}>© 2024, Coffydoor</p>*/}
      {/*  <div className={'text-xs'}>Privacy Policy</div>*/}
      {/*  <div className={'text-xs'}>Terms & Conditions</div>*/}
      {/*</div>*/}
    </footer>
  );
};

export const FooterItem: FC<FooterItems> = ({ href, label }) => {
  return (
    <Button variant={"link"} className={"last:col-span-2 last:text-center text-background"} asChild>
      <Link to={href}>{label}</Link>
    </Button>
  );
};
