import { serviceHost } from "@/services";
import { repositoryHost } from "@/repositories";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  IDOnlyPayload,
  UpdatePayload,
} from "@/core/ports/repositories/utilities.ts";
import { DiscountCodeData } from "@/core";

const databaseService = serviceHost.getDatabaseService();
const discountCodeRepository =
  repositoryHost.getDiscountCodeRepository(databaseService);

export const useGetDiscount = (payload: IDOnlyPayload) => {
  return useQuery({
    queryKey: ["customerProduct"],
    queryFn: () => {
      return discountCodeRepository.getDiscountCode(payload);
    },
  });
};

export const useUpdateDiscount = () => {
  return useMutation({
    mutationKey: ["customerProduct", "mutation", "update"],
    mutationFn: (payload: UpdatePayload<DiscountCodeData>) => {
      return discountCodeRepository.updateDiscountCode(payload);
    },
  });
};

// TEST FUNCTIONALITY: DEVELOPMENT ONLY
export const useSetDiscount = () => {
  return useMutation({
    mutationKey: ["customerProduct", "mutation", "set"],
    mutationFn: (payload: UpdatePayload<DiscountCodeData>) => {
      return discountCodeRepository.setDiscountCode(payload);
    },
  });
};
