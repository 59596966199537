export const formatPrice = (price: number, locale: string = 'se-SE') => {
  const priceFormat = price / 100;
  let formattedPrice =  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'SEK'
  }).format(priceFormat);

  if (formattedPrice.includes('kr')) {
    return formattedPrice;
  }

  formattedPrice = formattedPrice.replace('SEK', '') + ' kr';

  return formattedPrice;
};

export const formatPriceNormal = (price: number, locale: string = 'se-SE') => {
  let formattedPrice = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'SEK'
  }).format(price);

  if (formattedPrice.includes('kr')) {
    return formattedPrice;
  }

  // Replace "SEK" with "kr" in the formatted price string
  formattedPrice = formattedPrice.replace('SEK', 'kr');

  return formattedPrice;
};
