import { DatabaseService,DeliveryAddressRepository } from '@/core';

import { DatabaseCollection } from '@/repositories/config.ts';


//TODO: Check if collection path is correct
export function getDeliveryAddressRepository (database: DatabaseService): DeliveryAddressRepository {
  return {
    get (payload) {
      return database.get(DatabaseCollection.CUSTOMERS, payload.id);
    },
    update (payload) {
      return database.update(DatabaseCollection.CUSTOMERS, payload.id, payload.data);
    }
  };
}
