import {
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
  updatePassword,
  updateProfile,
  User,
} from "@firebase/auth";
import { firebase } from "@/infrastructure";
import { AuthenticationService, AuthUser } from "@/core";

export const authenticationService: AuthenticationService = {
  onUserStateChanged(callback) {
    return onAuthStateChanged(firebase.auth, async (user) => {
      if (!user) {
        callback(null);
        return;
      }

      callback(createAuthUser(user));
    });
  },

  async getCurrentUser() {
    return firebase.auth.currentUser;
  },

  async signInAnonymously() {
    await signInAnonymously(firebase.auth);
  },

  async signInWithCustomToken(token) {
    await signInWithCustomToken(firebase.auth, token);
  },

  async signInWithEmailAndPassword({ email, password }) {
    await signInWithEmailAndPassword(firebase.auth, email, password);
  },

  async updatePassword(password) {
    if (!firebase.auth.currentUser) {
      throw new Error("No user signed in");
    }

    await updatePassword(firebase.auth.currentUser, password);
  },

  async updateEmail(email) {
    if (!firebase.auth.currentUser) {
      throw new Error("No user signed in");
    }

    await updateEmail(firebase.auth.currentUser, email);
  },

  async updateProfile(data) {
    if (!firebase.auth.currentUser) {
      throw new Error("No user signed in");
    }

    await updateProfile(firebase.auth.currentUser!, data);
  },

  async sendPasswordResetEmail(email) {
    await sendPasswordResetEmail(firebase.auth, email);
  },

  async signOut() {
    await signOut(firebase.auth);
  },
};

function createAuthUser(user: User | null): AuthUser | null {
  if (!user) {
    return null;
  }

  if (!user.uid) {
    return null;
  }

  return {
    uid: user.uid,
    email: user.email || "",
    emailVerified: user.emailVerified,
    displayName: user.displayName || "",
    photoURL: user.photoURL || "",
  };
}
