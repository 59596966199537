import * as z from "zod";

import { baseEntitySchema } from "./base";
import { ProductBundleCapsulesQuantity } from "@/core";

// import { SubscriptionTypes } from "./subscription";

export enum DiscountCodeTypes {
  Limited = "limited",
  Unlimited = "unlimited",
}

export enum DiscountCodeAmountTypes {
  Fixed = "fixed",
  Percentage = "percentage",
}

export enum DiscountCodeLimitedTypes {
  PerCustomer = "perCustomer",
  ForCode = "forCode",
}

export const discountCodeDataSchema = z.object({
  code: z.string(),
  codeToLowerCase: z.string(),
  type: z.nativeEnum(DiscountCodeTypes),
  amount: z.number(),
  amountType: z.nativeEnum(DiscountCodeAmountTypes),
  usedBy: z.array(z.string()), // Front end mustn't touch this field
  activeSubscriptions: z.array(z.string()), // Front end mustn't touch this field
  startDate: z.date(),
  endDate: z.date().optional(),
  usageLimit: z.number().optional(),
  forInitialOrder: z.boolean(),
  forSubscriptionTypes: z.array(z.nativeEnum(ProductBundleCapsulesQuantity)),
  usedTimes: z.number(), // Front end mustn't touch this field
  limitType: z.nativeEnum(DiscountCodeLimitedTypes).optional(),
  // TODO Make sure the old records are updated, because z.record will make the key as string
  usedTimesPerCustomer: z.record(z.number(), z.number()).optional(), // Front end mustn't touch this field
});

export type DiscountCodeData = z.infer<typeof discountCodeDataSchema>;

export const discountCodeSchema = baseEntitySchema.merge(
  discountCodeDataSchema,
);

export type DiscountCode = z.infer<typeof discountCodeSchema>;

export const discountCodeDateFormat = "YYYY-MM-DD";
//
// export const discountMockData: DiscountCode = {
//   id: "1",
//   code: "DISCOUNT10",
//   codeToLowerCase: "discount10",
//   type: DiscountCodeTypes.Limited,
//   amount: 10,
//   amountType: DiscountCodeAmountTypes.Percentage,
//   usedBy: [],
//   activeSubscriptions: [],
//   startDate: new Date("2021-01-01"),
//   endDate: new Date("2021-12-31"),
//   usageLimit: 10,
//   forInitialOrder: true,
//   forSubscriptionTypes: [ProductBundleCapsulesQuantity.SIXTY],
//   usedTimes: 0,
//   limitType: DiscountCodeLimitedTypes.PerCustomer,
//   usedTimesPerCustomer: {},
// };
