import { useForm, UseFormProps } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubscriptionCancelReason,subscriptionCancelReasonSchema } from '@/core';

export function useCancelSubscriptionReasonForm(props: UseFormProps<SubscriptionCancelReason>) {
  return useForm<SubscriptionCancelReason>({
    ...props,
    resolver: zodResolver(subscriptionCancelReasonSchema),
    mode: "onChange",
  });
}
