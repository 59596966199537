import { useTranslation } from 'react-i18next';
import { FC, HTMLAttributes, useMemo, useState } from 'react';

import { Button } from '@/components/ui/button.tsx';
import { NextDeliveryForm } from '@/components/forms/next-delivery-form.tsx';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog.tsx';

import { SubscriptionData, SubscriptionStatus } from '@/core';

interface UpdateNextDeliveryDateProps extends HTMLAttributes<HTMLDivElement> {
  currentDeliveryDate: Date;
  subscription: SubscriptionData;
}

export const UpdateNextBillingDate: FC<UpdateNextDeliveryDateProps> = (
  props,
) => {
  const { currentDeliveryDate, subscription } = props;
  const { t } = useTranslation("translation");
  const [isOpen, setIsOpen] = useState<boolean | undefined>();

  const calculatedDeliveryDate = useMemo(() => {
    currentDeliveryDate.setMonth(currentDeliveryDate.getMonth() + 1);

    return currentDeliveryDate;
  }, [currentDeliveryDate]);

  const disabledBtn = useMemo(() => {
    return subscription.status === SubscriptionStatus.CANCELLED ||
      subscription.status === SubscriptionStatus.FAILED ||
      subscription.status === SubscriptionStatus.SKIPPED
  }, [subscription.status]);

  return (
    <Dialog open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <DialogTrigger asChild className={"w-full"}>
        <Button disabled={disabledBtn}>{t('update')}</Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle className={"flex justify-center"}>
            {t("updateYourNextDeliveryDate")}
          </DialogTitle>
          <div className={"space-y-4 text-center"}>
            <NextDeliveryForm
              closeModal={() => setIsOpen(false)}
              defaultValues={{
                date: calculatedDeliveryDate,
              }}
            />
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
