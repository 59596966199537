import {
  CustomerPortalUpdateSubscriptionPayload,
  KlarnaService,
} from "@/core/ports/services/klarna-service.ts";
import { httpsCallable } from "@firebase/functions";
import { firebase } from "@/infrastructure";

export const klarnaService: KlarnaService = {
  async customerPortalOrderForTomorrow() {
    await httpsCallable<void>(
      firebase.functions,
      "v2CustomerPortalOrderForTomorrow",
    )();
  },
  async customerPortalPauseSubscription() {
    await httpsCallable<void>(
      firebase.functions,
      "v2CustomerPortalPauseSubscription",
    )();
  },
  async customerPortalReactivateSubscription() {
    await httpsCallable<void>(
      firebase.functions,
      "v2CustomerPortalReactivateSubscription",
    )();
  },
  async customerPortalUpdateSubscription(payload) {
    await httpsCallable<CustomerPortalUpdateSubscriptionPayload, void>(
      firebase.functions,
      "v2CustomerPortalUpdateSubscription",
    )(payload);
  },
  async customerPortalChangePaymentMethod() {
    const result = await httpsCallable<undefined, string>(
      firebase.functions,
      "v2CustomerPortalChangePaymentMethod",
    )();

    return result.data;
  },
};
