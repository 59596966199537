import { DatabaseService, SubscriptionData, SubscriptionProductCapsulesConfig, SubscriptionRepository } from '@/core';
import { DatabaseCollection } from '@/repositories/config.ts';
import { Subscription, SubscriptionStatus } from '@/core/entities';


export function getSubscriptionRepository (database: DatabaseService): SubscriptionRepository {
  return {
    async getSubscription ({ id }) {
      const result = await database.get<Subscription>(DatabaseCollection.SUBSCRIPTIONS, id);

      if (!result) {
        throw new Error('Subscription not found');
      }

      return result;
    },
    async updateSubscription ({ id, data }) {
      return database.update<SubscriptionData>(DatabaseCollection.SUBSCRIPTIONS, id, data);
    },
    async updateCapsulesConfigSubscription ({ id, data }) {
      return database.update<SubscriptionProductCapsulesConfig>(DatabaseCollection.SUBSCRIPTIONS, id, data);
    },
    async cancelSubscription ({ id }) {
      //TODO: Ask what else needs to be updated when a subscription is cancelled.
      return database.update<SubscriptionData>(DatabaseCollection.SUBSCRIPTIONS, id, {
        status: SubscriptionStatus.CANCELLED
      });
    },
    // TEST FUNCTIONALITY: DEVELOPMENT ONLY
    async setSubscription (payload) {
      return database.set(DatabaseCollection.SUBSCRIPTIONS, payload.id, payload.data);
    },
  };
}
