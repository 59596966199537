import { Button } from '@/components/ui/button';

import { Sheet, SheetContent, SheetFooter, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import { FC, HTMLAttributes, ReactNode, useState } from 'react';
import { Icons } from '@/components/ui/icons.tsx';
import { NavigationItem } from '@/components/shared/header-mobile.tsx';
import { Link } from 'react-router-dom';
import { LocaleSwitcher } from '@/components/shared/lang-toggle.tsx';
import { ModeToggle } from '@/components/mode-toggle.tsx';

interface SidebarNavMobileProps extends HTMLAttributes<HTMLDivElement> {
  trigger: ReactNode;
  items: NavigationItem[];
}

export const SidebarNavMobile: FC<SidebarNavMobileProps> = (props) => {
  const { items, trigger } = props;
  const [isOpen, setIsOpen] = useState<boolean | undefined>();

  return (
    <Sheet open={isOpen}>
      <SheetTrigger asChild>
        <Button onClick={() => setIsOpen(true)} variant="invisible" className={'p-0'}>
          {trigger}
        </Button>
      </SheetTrigger>
      <SheetContent
        onClick={() => setIsOpen(false)}
        side={"left"}
        className={"flex flex-col justify-between h-full"}
      >
        <SheetHeader>
          <SheetTitle  className={"flex justify-center pt-6"}>
            <Icons.logo  />
          </SheetTitle>
          {/*<SheetDescription>*/}
          {/*  */}
          {/*</SheetDescription>*/}
        </SheetHeader>
        <div className="flex flex-col flex-grow pt-6">
          <ul role="list" className="-mx-2 space-y-1">
            {items.map((item, index) => (
              <li key={`index-${index}`}>
                <Button asChild variant={'ghost'} className={'w-full'}>
                  <Link to={item.href}>
                    {item.icon}
                    <p onClick={() => setIsOpen(false)} className={'text-left pl-3 w-full truncate'}>{item.label}</p>
                  </Link>
                </Button>
              </li>
            ))}
          </ul>
        </div>
        <SheetFooter className={"flex "}>
          <div className={"flex justify-between w-full border-t py-4"}>
            <LocaleSwitcher />
            <ModeToggle />
          </div>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};
