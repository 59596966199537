import * as z from "zod";

import { baseEntitySchema } from "./base";
import { Money } from "./utils/money";

// id will be the same as the customerID mapped 1:1
export const customerPointsDataSchema = z.object({
  points: z.number(),
});

export type CustomerPointsData = z.infer<typeof customerPointsDataSchema>;

export const customerPointsSchema = baseEntitySchema.merge(
  customerPointsDataSchema,
);

export type CustomerPoints = z.infer<typeof customerPointsSchema>;

/**
 * Calculate the points for a given amount of money.
 *
 * @param {Money} money
 *
 * @returns {number}
 */
export function calculatePointsForMoney(money: Money): number {
  return Math.floor(money.amount / 10);
}
