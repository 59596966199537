import { useTranslation } from "react-i18next";
import { FC, HTMLAttributes, useCallback, useEffect } from "react";
import { useBillNow } from "@/hooks";
import { useToast } from "@/components/ui/use-toast";
import { DestructiveAlert } from "@/components/shared/DestructiveAlert.tsx";

interface BillNowCustomerProps extends HTMLAttributes<HTMLDivElement> {
}

export const BillNowCustomer: FC<BillNowCustomerProps> = () => {
  const { t } = useTranslation('translation');
  const { toast } = useToast();

  const billNow = useBillNow();

  const handleBillNow = useCallback(() => {
    if (billNow.isPending) {
      return;
    }

    if (billNow.isSuccess) {
      return;
    }

    billNow.mutate();
  }, [billNow]);

  useEffect(() => {
    if (billNow.isSuccess) {
      toast({
        title: t("billedNowSuccessfully"),
      });
    }
  }, [toast, billNow.isSuccess, t]);

  useEffect(() => {
    if (billNow.isError) {
      toast({
        title: t("failedToBillNow"),
      });
    }
  }, [billNow.isError, t, toast]);


  return (
    // <Dialog open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
    //   <DialogTrigger asChild className={'w-full'}>
    //     <Button variant={'black'}>
    //       {t('billNow')}
    //     </Button>
    //   </DialogTrigger>
    //
    //   <DialogContent>
    //     <DialogHeader>
    //       <DialogTitle className={'flex justify-center'}>
    //         {t('areYouSure')}
    //       </DialogTitle>
    //       <div className={'flex  justify-center gap-6 pt-10 text-center'}>
    //         <Button
    //           onClick={handleBillNow}
    //           disabled={billNow.isPending}
    //         >
    //           {billNow.isPending && <Icons.loading/>}
    //           {t('yes')}
    //         </Button>
    //         <DialogClose>
    //           <Button variant={'destructive'}>{t('no')}</Button>
    //         </DialogClose>
    //       </div>
    //     </DialogHeader>
    //   </DialogContent>
    // </Dialog>
    <DestructiveAlert
      isDropdown={false}
      isActive={true}
      title={t('areYouSure')}
      description={''}
      triggerText={t('billNow')}

      confirmVariant={'bill'}
      cancelText={t('no')}
      confirmText={t('yes')}

      confirmAction={handleBillNow}
      confirmActionLoading={billNow.isPending}
    />
  );
};
