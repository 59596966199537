import { DatabaseService, OrderHistoryRepository } from '@/core';
import { DatabaseCollection } from '@/repositories/config.ts';
import { Order } from '@/core/entities';

export function getOrderHistoryRepository(database: DatabaseService): OrderHistoryRepository {
  return {
    async getOrders ({ id }) {
      const result = await database.getPaginated<Order>(
        DatabaseCollection.ORDER_HISTORY,
        [
          {
            field: 'customerID',
            operator: '==',
            value: id
          }
        ],
        {},
        {
          field: 'createdAt',
          direction: 'desc'
        }
      );

      console.log('result', result);
      if (!result) {
        return [];
      }

      return result;
    },
    // TEST FUNCTIONALITY: DEVELOPMENT ONLY
    setOrders (payload) {
      return database.set(DatabaseCollection.ORDER_HISTORY, payload.id, payload.data);
    },
  };
}
