import { Outlet, useNavigate } from 'react-router-dom';
import { FC } from 'react';
import { Separator } from '@/components/ui/separator.tsx';
import { UserPoints } from '@/components/user-points.tsx';
import { useTranslation } from 'react-i18next';
import { RoutePath } from '@/routes/constants/route-paths.ts';
import { useAuthUser } from '@/hooks/service-hooks/auth/use-auth.ts';
import { BillNowCustomer } from '@/pages/subscription-manage/components/bill-now.tsx';
import { useGetSubscription } from '@/hooks';

export const AppLayout: FC = () => {
  const authUser = useAuthUser();
  const navigate = useNavigate();
  const { t } = useTranslation('translation');

  const {
    data: subscription
  } = useGetSubscription({ id: authUser?.uid || '' });


  if (!authUser?.uid) {
    navigate(RoutePath.LOGIN);
  }


  return (
    <div>
      <div className={"py-0 sm:py-2"}>
        <div className={"flex flex-wrap justify-between sm:gap-6"}>
          <div className={""}>
            <p className={"text-4xl lg:text-6xl font-light capitalize"}>
              {t("hello")}, {authUser?.displayName}
            </p>
            <p className={"my-2 font-extralight"}>
              {t("rootManageSubscription")}
            </p>
          </div>

          <div className={""}>
            <div className={"flex flex-col gap-6"}>
              <UserPoints />
              {subscription && <BillNowCustomer />}
            </div>
          </div>
        </div>
        <Separator className={"my-4 sm:my-8"} />
      </div>
      <div className={"sm:p-4"}>
        <Outlet />
      </div>
    </div>
  );
};
