import { serviceHost } from '@/services';
import { repositoryHost } from '@/repositories';
import { useQuery } from '@tanstack/react-query';
import { IDOnlyPayload } from '@/core/ports/repositories/utilities.ts';

const databaseService = serviceHost.getDatabaseService();
const orderHistoryRepository = repositoryHost.getOrderHistoryRepository(databaseService);

export const useGetOrderHistory = (payload: IDOnlyPayload) => {
  return useQuery({
    queryKey: ['orderHistory', 'query', 'get'],
    queryFn: () => {
      return orderHistoryRepository.getOrders(payload);
    }
  });
};

// TEST FUNCTIONALITY: DEVELOPMENT ONLY
// export const useSetOrderHistory = () => {
//   return useMutation({
//     mutationKey: ['orderHistory', 'mutation', 'set'],
//     mutationFn: (payload: UpdatePayload<OrderData>) => {
//       return orderHistoryRepository.setOrders(payload);
//     }
//   });
// };
