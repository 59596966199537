import { Icons } from "@/components/ui/icons.tsx";
import { FC } from "react";

interface EmptyStateProps {
  message?: string;
  title?: string;
}
export const EmptyState: FC<EmptyStateProps> = ({
  title = "No data available",
  message = "Please try again later or contact support if the problem persists.",
}) => {
  return (
    <div
      className={"w-full flex flex-col justify-center items-center h-fit p-6"}
    >
      <Icons.empty className="h-8 w-8 text-foreground fill-secondary " />
      <div
        className={"text-muted-foreground text-center text-lg font-semibold"}
      >
        {title}
        <br />
        <p
          className={
            "text-muted-foreground text-center text-sm font-normal mt-4 max-w-sm"
          }
        >
          {message}
        </p>
      </div>
    </div>
  );
};
