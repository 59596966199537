import { httpsCallable } from '@firebase/functions';
import { firebase } from '@/infrastructure';

import {
  ApplyDiscountCodePayload,
  DiscountCodeService,
} from '@/core/ports/services/discount-code-service.ts';

export const discountCodeService: DiscountCodeService = {
  async applyDiscountCode (payload) {
    await httpsCallable<ApplyDiscountCodePayload, void>(
      firebase.functions,
      'v2CustomerPortalApplyDiscountCode'
    )(payload);
  },
  async removeDiscountCode () {
    await httpsCallable<void>(
      firebase.functions,
      'v2CustomerPortalRemoveDiscountCode'
    )();
  }
};
