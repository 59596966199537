import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Input } from "@/components/ui/input.tsx";
import { Button } from "@/components/ui/button.tsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";

import { useCancelSubscriptionReasonForm } from "@/hooks/forms/entities/form/use-cancel-subscription-reason-form.ts";

import { SubscriptionCancelReason } from "@/core";

import { Icons } from "../ui/icons";

interface CancelSubscriptionReasonFormProps {
  onSubmit: (data: SubscriptionCancelReason) => void;
  isLoading: boolean;
}

export const CancelSubscriptionReasonForm: FC<
  CancelSubscriptionReasonFormProps
> = (props) => {
  const { onSubmit, isLoading } = props;
  const { t } = useTranslation("translation");

  const form = useCancelSubscriptionReasonForm({
    defaultValues: {
      cancelReason: "otherReason", // Default reason MUST be "otherReason"
      otherReason: "",
    },
    mode: "onChange",
  });

  const cancelReason = form.watch("cancelReason");

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-2/3 space-y-6">
        <FormField
          control={form.control}
          name="cancelReason"
          render={({ field }) => (
            <FormItem>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder={t("itsTooExpensive")} />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value={t("itsTooExpensive")}>
                    {t("itsTooExpensive")}
                  </SelectItem>
                  <SelectItem value={t("wrongOrder")}>
                    {t("wrongOrder")}
                  </SelectItem>
                  <SelectItem value={t("tooMuchCoffee")}>
                    {t("tooMuchCoffee")}
                  </SelectItem>
                  <SelectItem value={t("needFasterDelivery")}>
                    {t("needFasterDelivery")}
                  </SelectItem>
                  <SelectItem value={t("stoppedDrinkingCoffee")}>
                    {t("stoppedDrinkingCoffee")}
                  </SelectItem>
                  <SelectItem value={t("coffeeProblem")}>
                    {t("coffeeProblem")}
                  </SelectItem>
                  <SelectItem value={"otherReason"}>
                    {t("otherReason")}
                  </SelectItem>
                </SelectContent>
              </Select>

              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="otherReason"
          render={({ field }) => (
            <FormItem>
              {cancelReason === "otherReason" && (
                <>
                  <div className={"pt-8"}>
                    <p className={"text-xl font-medium text-center pb-4"}>
                      {t("cancelQuestion")}
                    </p>
                    <Input {...field} />
                  </div>
                  <FormMessage />
                </>
              )}
            </FormItem>
          )}
        />
        <div className={"flex justify-center gap-10"}>
          <Button disabled={isLoading} variant="destructive">
            {isLoading && <Icons.loading />}
            {t("cancelSubscription")}
          </Button>
        </div>
      </form>
    </Form>
  );
};
