import { z } from "zod";

import { baseEntitySchema } from "./base";
import { moneySchema } from "./utils/money";

export enum ProductBundleCapsulesQuantity {
  SIXTY = 60,
  ONE_HUNDRED_TWENTY = 120,
  TWO_HUNDRED_FIFTY = 250,
  FIVE_HUNDRED = 500,
}

export const productBundleCapsulesConfiguration = z.object({
  espresso: z.number(),
  lungo: z.number(),
  ristretto: z.number(),
  decaf: z.number(),
});

export type ProductBundleCapsulesConfiguration = z.infer<
  typeof productBundleCapsulesConfiguration
>;

export const productBundleAdditionalProduct = z.object({
  productID: z.string(), // represents the product ID
  variantID: z.string(), // represents the variant ID
  name: z.string(), // cup, tin, adapter, etc todo enum
  quantity: z.number().int().min(1),
});

export type ProductBundleAdditionalProduct = z.infer<
  typeof productBundleAdditionalProduct
>;

export const productBundleConfigSchema = z.object({
  capsulesQuantity: z.nativeEnum(ProductBundleCapsulesQuantity),
  capsulesConfiguration: productBundleCapsulesConfiguration,
  additionalProducts: z.array(productBundleAdditionalProduct),
});

export type ProductBundleConfig = z.infer<typeof productBundleConfigSchema>;

export const productBundleDataSchema = z.object({
  productID: z.string(),
  variants: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      price: moneySchema,
    }),
  ),
  config: productBundleConfigSchema,
});

export type ProductBundleData = z.infer<typeof productBundleDataSchema>;

export const productBundleSchema = baseEntitySchema.merge(
  productBundleDataSchema,
);

export type ProductBundle = z.infer<typeof productBundleSchema>;
