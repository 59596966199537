export enum DatabaseCollection {
  USERS = "v2_users",
  PRODUCT_BUNDLES = "v2_product-bundles",
  ACTIVITY_LOGS = "v2_activity-logs",
  DISCOUNT_CODES = "v2_discount-codes",
  IDEMPOTENCY_LOGS = "v2_idempotency-logs",
  SUBSCRIPTIONS = "v2_subscriptions",
  ORDER_HISTORY = "v2_order-history",
  CUSTOMERS = "v2_customers",
  CUSTOMER_POINTS = "v2_customer-points",
  CUSTOMER_REFERRALS = "v2_customer-referrals",
  CUSTOMER_RESEND = "v2_customer-resend",
}
