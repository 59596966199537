import * as z from "zod";

import { baseEntitySchema } from "./base";
import { ProductBundleCapsulesQuantity } from "@/core";
import { moneySchema } from "./utils/money";

export enum SubscriptionInterval {
  TWO_WEEKS = "two-weeks",
  FOUR_WEEKS = "four-weeks",
  SIX_WEEKS = "six-weeks",
  EIGHT_WEEKS = "eight-weeks",
  TWELVE_WEEKS = "twelve-weeks",
}

export const subscriptionIntervalUnit = "weeks";

export const subscriptionIntervalToNumber = {
  [SubscriptionInterval.TWO_WEEKS]: 2,
  [SubscriptionInterval.FOUR_WEEKS]: 4,
  [SubscriptionInterval.SIX_WEEKS]: 6,
  [SubscriptionInterval.EIGHT_WEEKS]: 8,
  [SubscriptionInterval.TWELVE_WEEKS]: 12,
};

export enum SubscriptionStatus {
  ACTIVE = "ACTIVE",
  SKIPPED = "SKIPPED",
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
}

export const subscriptionAdditionalProductSchema = z.object({
  name: z.string(),
  quantity: z.number().int().min(1),
  productID: z.string(),
  variantID: z.string(),
  price: moneySchema,
  isRecurring: z.boolean(), // if true, the product will be added to the subscription on every interval
  images: z.array(z.string()),
});

export type SubscriptionAdditionalProductData = z.infer<
  typeof subscriptionAdditionalProductSchema
>;

const capsulesConfigSchema = z.object({
  espresso: z.number(),
  lungo: z.number(),
  ristretto: z.number(),
  decaf: z.number(),
});

export type CapsulesConfig = z.infer<typeof capsulesConfigSchema>;

export const subscriptionProductDataSchema = z.object({
  name: z.string(),
  productID: z.string(),
  variantID: z.string(),
  capsulesQuantity: z.nativeEnum(ProductBundleCapsulesQuantity),
  capsulesConfig: capsulesConfigSchema,
  images: z.array(z.string()),
  price: moneySchema,
});

// id will be the same as the customerID mapped 1:1
export const subscriptionDataSchema = z.object({
  interval: z.nativeEnum(SubscriptionInterval),
  nextBillingDate: z.date(), // Delivery is after creating the order
  nextBillingDateAsString: z.string(), // format 'YYYY-MM-DD'
  status: z.nativeEnum(SubscriptionStatus),
  subscriptionProduct: subscriptionProductDataSchema,
  additionalProducts: z.array(subscriptionAdditionalProductSchema),
  discountCode: z.string().optional(),
  shopifyCustomerID: z.number(),
  cancelReason: z.string().optional(),
  isOldPricing: z.boolean().default(false).optional(),
});

export const subscriptionCancelReasonSchema = z.object({
  cancelReason: z.string(),
  otherReason: z.string().optional(),
});

export type SubscriptionCancelReason = z.infer<
  typeof subscriptionCancelReasonSchema
>;

export type SubscriptionData = z.infer<typeof subscriptionDataSchema>;

export const subscriptionProductCapsulesConfig = subscriptionDataSchema.pick({
  subscriptionProduct: true,
});

export type SubscriptionProductCapsulesConfig = z.infer<
  typeof subscriptionProductCapsulesConfig
>;

export const subscriptionSchema = baseEntitySchema.merge(
  subscriptionDataSchema,
);

export type Subscription = z.infer<typeof subscriptionSchema>;

// export const subscriptionMockData: Subscription = {
//   id: "1",
//   interval: SubscriptionInterval.FOUR_WEEKS,
//   nextBillingDate: new Date(),
//   nextBillingDateAsString: "2022-01-01",
//   status: SubscriptionStatus.ACTIVE,
//   subscriptionProduct: {
//     name: "Subscription Product",
//     productID: "1",
//     variantID: "1",
//     capsulesQuantity: ProductBundleCapsulesQuantity.ONE_HUNDRED_TWENTY,
//     capsulesConfig: {
//       espresso: 10,
//       lungo: 10,
//       ristretto: 10,
//       decaf: 0,
//     },
//     images: ["image1", "image2"],
//     price: {
//       amount: 100,
//       currency: "USD",
//     },
//   },
//   additionalProducts: [
//     {
//       name: "Additional Product",
//       quantity: 1,
//       productID: "2",
//       variantID: "2",
//       price: {
//         amount: 50,
//         currency: "USD",
//       },
//       isRecurring: true,
//       images: ["image1", "image2"],
//     },
//   ],
//   discountCode: "DISCOUNT",
//   shopifyCustomerID: 1,
// };
