import { z } from 'zod';

export const deliveryAddressSchema = z.object({
  street: z.string(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
});

export const partialDeliveryAddressSchema = deliveryAddressSchema.partial();

export type PartialDeliveryAddress = z.infer<typeof partialDeliveryAddressSchema>;

export type DeliveryAddress = z.infer<typeof deliveryAddressSchema>;
