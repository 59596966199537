import { Customer, CustomerData, CustomerRepository, DatabaseService } from '@/core';

import { DatabaseCollection } from '@/repositories/config.ts';

export function getCustomerRepository (database: DatabaseService): CustomerRepository {
  return {
    async getCustomer ({ id }) {
      const result = await database.get<Customer>(DatabaseCollection.CUSTOMERS, id);
      if (!result) {
        throw new Error('Customer not found');
      }
      return result;
    },
    async updateCustomer ({ id, data }) {
      return database.update<CustomerData>(DatabaseCollection.CUSTOMERS, id, data);
    },
    async deleteCustomer ({ id }) {
      return database.delete(DatabaseCollection.CUSTOMERS, id);
    },
    // TEST FUNCTIONALITY: DEVELOPMENT ONLY
    async setCustomer ({ id, data }) {
      return database.set(DatabaseCollection.CUSTOMERS, id, data);
    },
  };
}
