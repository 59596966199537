import { Icons } from '@/components/ui/icons.tsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface LoadingStateProps {
  title?: string;
  description?: string;
}
export const LoadingState: FC<LoadingStateProps> = () => {
  const { t } = useTranslation('translation');

  return (
    <div
      className={
        "w-full flex flex-col justify-center items-center space-y-6 h-96"
      }
    >
      <div className={"flex gap-3 flex-col"}>
        <h2
          className={
            "text-xl text-center font-bold text-foreground fill-secondary"
          }
        >
          {t('loading')}
        </h2>

        <p
          className={
            "text-sm text-center font-light text-foreground fill-secondary"
          }
        >
          {t('loadingDescription')}
        </p>
      </div>
      <div>
        <Icons.loading className="h-8 w-8 text-foreground fill-secondary animate-spin" />
      </div>
    </div>
  );
};
