import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApplyDiscountCodePayload } from '@/core';
import { serviceHost } from '@/services';

const discountService = serviceHost.getDiscountCodeService();

export const useApplyDiscountCode = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['discount-codes', 'mutation', 'apply'],
    mutationFn: (payload: ApplyDiscountCodePayload) => {
      return discountService.applyDiscountCode(payload);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['subscription']
      });
    }
  });
}

export const useRemoveDiscountCode = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['discount-codes', 'mutation', 'remove'],
    mutationFn: () => {
      return discountService.removeDiscountCode();
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['subscription']
      });
    }
  });
};
