import { useTranslation } from 'react-i18next';
import { FC, useCallback, useEffect } from 'react';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useToast } from '@/components/ui/use-toast.ts';

import { useSkipSubscription } from '@/hooks';

import { Icons } from '@/components/ui/icons.tsx';

import { Subscription } from '@/core';

interface PauseSubscriptionProps {
  subscription: Subscription;
}

export const SkipSubscription: FC<PauseSubscriptionProps> = () => {
  const { t } = useTranslation("translation");
  const { toast } = useToast();

  const skipSubscription = useSkipSubscription();

  const onSubmit = useCallback(() => {
    skipSubscription.mutate();
  }, [skipSubscription]);

  useEffect(() => {
    if (skipSubscription.isSuccess) {
      toast({
        title: t("subscriptionPaused"),
        description: t("subscriptionPausedDescription"),
      });
    }
  }, [skipSubscription.isSuccess, t, toast]);

  useEffect(() => {
    if (skipSubscription.isError) {
      toast({
        title: t("error"),
        description: t("anErrorOccurred"),
      });
    }
  }, [skipSubscription.isError, t, toast]);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant={'black'} className={'max-w-48'}>
          {t("pauseSubscription")}
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("pauseSubscription")}</DialogTitle>
          <DialogDescription className={"max-w-sm"}>
            {t("areYouSureYouWantToPause")}
          </DialogDescription>
        </DialogHeader>


        <Button
          onClick={onSubmit}
          disabled={skipSubscription.isPending}
          className={'mx-auto mt-10'}
        >
          {skipSubscription.isPending && <Icons.loading/>}
          {t("pause")}
        </Button>
      </DialogContent>
    </Dialog>
  );
};
