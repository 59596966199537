import defaultImage from "@/assets/images/login-logo.png";
import { Button } from "@/components/ui/button.tsx";
import { Link } from "react-router-dom";
import { RoutePath } from "@/routes/constants/route-paths.ts";
import { AspectRatio } from "@/components/ui/aspect-ratio.tsx";
import { useTranslation } from "react-i18next";
import { useAuthUser } from "@/hooks/service-hooks/auth/use-auth.ts";

export function LoginPage() {
  const { t } = useTranslation("translation");
  const authUser = useAuthUser();

  return (
    <div
      className={
        "flex flex-col-reverse justify-center lg:h-fit w-full mx-auto lg:grid lg:grid-cols-2 items-center"
      }
    >
      <div
        className={
          "bg-primary w-full flex flex-col z-20 h-full relative pt-12 mt-6"
        }
      >
        <div
          className={
            "relative lg:w-[48rem] max-w-screen-lg lg:max-w-screen-2xl"
          }
        >
          <AspectRatio ratio={1}>
            <img src={defaultImage} alt="coffe box" />
          </AspectRatio>
        </div>

        <div className={"absolute lg:relative bottom-4 w-full"}>
          <p
            className={
              "font-light text-primary-foreground text-center mt-42 text-2xl"
            }
          >
            {t("beAlwaysFresh")}
          </p>
        </div>
      </div>

      <div
        className={
          "flex flex-col justify-center lg:pl-24 z-20 h-1/2  lg:h-full"
        }
      >
        <h3 className={"text-2xl lg:text-6xl font-light pt-4"}>
          {t("myCoffydoorPortal")}
        </h3>
        <div className={"space-y-3 mt-6"}>
          {authUser ? (
            <Link to={RoutePath.SUBSCRIPTION}>
              <Button>{t("goToSubscription")}</Button>
            </Link>
          ) : (
            <a href={"https://coffydoor.com/account"}>
              <Button className={"w-full"}>{t("login")}</Button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
