import { Outlet, useNavigate } from "react-router-dom";
import { FC, useEffect } from "react";
import { Header } from "@/components/shared/header.tsx";
import { Footer } from "@/components/shared/footer.tsx";
import { cn } from "@/lib/utils.ts";
import { RoutePath } from "@/routes/constants/route-paths.ts";
import { HeaderMobile } from "@/components/shared/header-mobile.tsx";
import { Toaster } from "@/components/ui/toaster";
import { useSignInUser } from "@/hooks/service-hooks/auth/use-sign-in-user.ts";
import { useAuthUser } from "@/hooks/service-hooks/auth/use-auth.ts";
import { LoadingState } from "@/components/shared/LoadingState.tsx";
import { ErrorState } from "@/components/shared/ErrorState.tsx";

export const RootLayout: FC = () => {
  const authUser = useAuthUser();
  const { isLoading, error } = useSignInUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authUser && !isLoading) {
      navigate(RoutePath.LOGIN);
    }
  }, [authUser, isLoading, navigate]);

  if (isLoading && !authUser) {
    return <LoadingState />;
  }

  if (error) {
    return <ErrorState />;
  }

  return (
    <div
      className={cn(
        !authUser && !isLoading ? "login-linear-gradient" : "relative",
      )}
    >
      {/*<MobileNav*/}
      {/*  links={[*/}
      {/*    {*/}
      {/*      label: "Subscription",*/}
      {/*      href: RoutePath.SUBSCRIPTION,*/}
      {/*      icon: <Icons.subscription />,*/}
      {/*    },*/}
      {/*    {*/}
      {/*      label: "Manage subscription",*/}
      {/*      href: RoutePath.SUBSCRIPTION_MANAGE,*/}
      {/*      icon: <Icons.manageSubscription />,*/}
      {/*    },*/}
      {/*    {*/}
      {/*      label: "Orders",*/}
      {/*      href: RoutePath.SUBSCRIPTION_ORDERS,*/}
      {/*      icon: <Icons.orderHistory />,*/}
      {/*    },*/}
      {/*    {*/}
      {/*      label: "My Profile",*/}
      {/*      href: RoutePath.SUBSCRIPTION_PROFILE,*/}
      {/*      icon: <Icons.profile />,*/}
      {/*    },*/}
      {/*  ]}*/}
      {/*/>*/}

      <Header className={"hidden sm:flex"} />
      <HeaderMobile className={"lg:hidden  py-2 px-4"} />
      <div
        className={
          "max-w-7xl mx-auto w-full flex flex-col min-h-screen  p-4 lg:p-0"
        }
      >
        <div className={"lg:p-4 flex-grow"}>
          <Outlet />
        </div>
      </div>
      <Footer />
      <Toaster />
    </div>
  );
};
