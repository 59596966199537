import { useAuthUser } from "@/hooks/service-hooks/auth/use-auth.ts";
import { useGetSubscription } from "@/hooks";
import { ProductCard } from "@/pages/subscription/components/product-card.tsx";

export const AdditionalProducts = () => {
  const authUser = useAuthUser();
  const {
    data: subscription,
    isLoading: isSubscriptionLoading,
    isError: isSubscriptionError,
  } = useGetSubscription({ id: authUser?.uid || "" });

  if (isSubscriptionLoading) {
    return null;
  }

  if (isSubscriptionError) {
    return null;
  }

  if (!subscription) {
    return null;
  }

  return (
    <div>
      {subscription.additionalProducts.length > 0
        ? subscription.additionalProducts.map((product, index) => (
            <ProductCard
              key={index}
              avatar={product.images[0]}
              label={product.name}
              price={product.price.amount}
              starsPrice={product.price.amount}
            />
          ))
        : null}
    </div>
  );
};
