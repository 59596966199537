import { FC, HTMLAttributes } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card.tsx';
import { Button } from '@/components/ui/button.tsx';
import { AspectRatio } from '@/components/ui/aspect-ratio.tsx';
import { Icons } from '@/components/ui/icons.tsx';
import { AddProductToNextOrder } from '@/pages/subscription/components/add-product-to-next-order.tsx';
import { useTranslation } from 'react-i18next';
import { formatPriceNormal } from '@/utils/format/format-price.ts';

interface ProductCardProps extends HTMLAttributes<HTMLDivElement> {
  avatar: string;
  label: string;
  price: number;
  isOneTime?: boolean;
  starsPrice: number;
}

export const ProductCard: FC<ProductCardProps> = (props) => {
  const { avatar, label, price, isOneTime, starsPrice } = props;

  const { t } = useTranslation("translation");
  console.log("Product Card props:", avatar, label, price);

  return (
    <Card className={"h-fit"}>
      <CardHeader>
        <div className="flex items-center justify-between space-x-4">
          <div className="flex items-center space-x-4">
            <div className="w-20">
              <AspectRatio ratio={16 / 9}>
                <img
                  src={avatar}
                  alt="Image"
                  className="rounded-sm object-cover"
                />
              </AspectRatio>
            </div>

            <div className={""}>
              <CardTitle className={"text-base"}>{label}</CardTitle>
              <CardDescription className={"flex items-center"}>
                {formatPriceNormal(price)} | {starsPrice}{' '}
                <Icons.star className={"w-4 h-4 ml-1"} />
              </CardDescription>
            </div>
          </div>
        </div>
      </CardHeader>
      <CardContent className="grid  gap-6 mt-6">
        <div className={"flex justify-between gap-4 w-full"}>
          {!isOneTime && (
            <Button variant="default" size={"sm"}>
              {t("subscription")}
            </Button>
          )}

          <AddProductToNextOrder
            triggerButtonLabel={t("oneTime")}
            mainLabel={t("addItToYourNextOrder")}
            mainButtonAction={() => {}}
            mainButtonLabel={"Add to next order"}
            avatar="https://img.freepik.com/free-photo/beautiful-aerial-shot-fronalpstock-mountains-switzerland-beautiful-pink-blue-sky_181624-9315.jpg?w=1800&t=st=1711025769~exp=1711026369~hmac=c3305861bb6a86f8c50428a4bca7f294caecbd56c5e2ac913a95e48cf659ba63"
            label="Coffydoor Cup"
            price={80}
            className={""}
            starsPrice={90}
          />
        </div>
      </CardContent>
    </Card>
  );
};
