import { useForm, UseFormProps } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  NextDeliveryDate,
  nextDeliverySchema,
} from "@/core/entities/forms/next-delivery-date.ts";

export function useNextDeliveryForm(props: UseFormProps<NextDeliveryDate>) {
  return useForm<NextDeliveryDate>({
    ...props,
    resolver: zodResolver(nextDeliverySchema),
    mode: "onChange",
  });
}
