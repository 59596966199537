import { serviceHost } from "@/services";
import { repositoryHost } from "@/repositories";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  IDOnlyPayload,
  UpdatePayload,
} from "@/core/ports/repositories/utilities.ts";
import { SubscriptionData, SubscriptionProductCapsulesConfig } from "@/core";

const databaseService = serviceHost.getDatabaseService();
const subscriptionRepository =
  repositoryHost.getSubscriptionRepository(databaseService);

export const useGetSubscription = (payload: IDOnlyPayload) => {
  return useQuery({
    queryKey: ["subscription"],
    queryFn: () => {
      return subscriptionRepository.getSubscription(payload);
    },
  });
};

export const useUpdateSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["subscription"],
    mutationFn: (payload: UpdatePayload<SubscriptionData>) => {
      return subscriptionRepository.updateSubscription(payload);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["subscription"],
      });
    },
  });
};


export const useUpdateCapsulesConfigSubscription = () => {
  return useMutation({
    mutationKey: ["subscription", "mutation", "update"],
    mutationFn: (payload: UpdatePayload<SubscriptionProductCapsulesConfig>) => {
      return subscriptionRepository.updateCapsulesConfigSubscription(payload);
    },
  });
};

// TEST FUNCTIONALITY: DEVELOPMENT ONLY
export const useSetSubscription = () => {
  return useMutation({
    mutationKey: ["subscription", "mutation", "set"],
    mutationFn: (payload: UpdatePayload<SubscriptionData>) => {
      return subscriptionRepository.setSubscription(payload);
    },
  });
};
