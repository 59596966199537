import { SubscriptionData } from "@/core";
import { FC } from "react";
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import { AspectRatio } from "@/components/ui/aspect-ratio.tsx";
import { Badge } from "@/components/ui/badge.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Icons } from "@/components/ui/icons.tsx";
import { useTranslation } from "react-i18next";

interface SubscriptionProductSummaryProps {
  subscription: SubscriptionData;
}
export const SubscriptionAdditionalProducts: FC<
  SubscriptionProductSummaryProps
> = ({ subscription }) => {
  const { t } = useTranslation("translation");

  return (
    <>
      <CardHeader>
        <CardTitle className={"font-semibold"}>Additional Items</CardTitle>
        <CardDescription className={"font-extralight"}>
          {t("additionalItems")}
        </CardDescription>
      </CardHeader>

      <CardContent className={"space-y-6"}>
        {subscription.additionalProducts.map((product) => (
          <AdditionalItem
            quantity={product.quantity}
            image={product.images[0] || ""}
            label={product.name}
            itemPoints={product.price.amount}
            saleType={product.isRecurring ? "Subscription" : "One Time"}
          />
        ))}
        <Separator />
      </CardContent>
    </>
  );
};

interface AdditionalItemProps {
  image: string;
  label: string;
  itemPoints: number;
  saleType: "One Time" | "Subscription";
  quantity: number;
}

const AdditionalItem: FC<AdditionalItemProps> = ({
  image,
  label,
  itemPoints,
  saleType,
  quantity,
}) => {
  return (
    <div className={"flex flex-wrap gap-4 h-full justify-between items-center"}>
      <div className={"flex gap-2 items-center flex-wrap"}>
        <div className={"w-20"}>
          <AspectRatio ratio={1}>
            <img
              src={image}
              alt="coffe box"
              className="rounded-lg  w-full h-full object-cover"
            />
          </AspectRatio>
        </div>
        <div>
          <h1>
            <span className={"font-semibold"}>{label}</span>
          </h1>
          <Badge variant="secondary">{saleType}</Badge>
        </div>
      </div>

      <div className={"flex gap-8 justify-between w-full"}>
        <ItemQuantitySelector
          quantity={quantity}
          setQuantity={(quantity) => console.log(quantity)}
        />
        <ItemPoints points={itemPoints} />
      </div>
    </div>
  );
};

interface ItemQuantitySelectorProps {
  quantity: number;
  setQuantity: (quantity: number) => void;
}

const ItemQuantitySelector: FC<ItemQuantitySelectorProps> = ({
  quantity,
  setQuantity,
}) => {
  const increment = () => {
    setQuantity(quantity + 1);
  };

  const decrement = () => {
    setQuantity(quantity - 1);
  };

  return (
    <div className={"flex gap-4 items-center  border p-2 rounded-lg"}>
      <Button onClick={decrement} size={"icon"} variant={"secondary"}>
        <Icons.minus className={"w-4 h-4"} />
      </Button>
      <span
        className={
          "w-6 h-6 flex items-center justify-center text-lg font-semibold"
        }
      >
        {quantity}
      </span>
      <Button onClick={increment} size={"icon"} variant={"secondary"}>
        <Icons.plus className={"w-4 h-4"} />
      </Button>
    </div>
  );
};

interface ItemPointsProps {
  points: number;
}

const ItemPoints: FC<ItemPointsProps> = ({ points }) => {
  return (
    <div className={"flex gap-2 items-center"}>
      <span className={"text-base font-semibold"}>{points}</span>
      <Icons.star className={"w-5 h-5"} />
    </div>
  );
};
