import * as z from "zod";

import { baseEntitySchema } from "./base";

// id will be the same as the customerID mapped 1:1
export const customerResendDataSchema = z.object({
  date: z.string(),
  count: z.number(),
});

export type CustomerResendData = z.infer<typeof customerResendDataSchema>;

export const customerResendSchema = baseEntitySchema.merge(
  customerResendDataSchema,
);

export type CustomerResend = z.infer<typeof customerResendSchema>;
