import { useForm, UseFormProps } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CustomerProfileData, customerProfileDataSchema } from "@/core";

export function useProfileForm(props: UseFormProps<CustomerProfileData>) {
  return useForm<CustomerProfileData>({
    ...props,
    resolver: zodResolver(customerProfileDataSchema),
    mode: "onChange",
  });
}
