import { useMutation, useQueryClient } from "@tanstack/react-query";
import { serviceHost } from "@/services";
import { Address, UpdateCustomerPayload } from "@/core";

const customerService = serviceHost.getCustomerService();

export const useUpdateCustomerProfile = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["customer", "mutation", "update"],
    mutationFn: (payload: UpdateCustomerPayload) => {
      return customerService.updateCustomerProfile(payload);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["customer"],
      });
    },
  });
};

interface UpdateCustomerAddressPayload {
  address: Address;
}

export const useUpdateCustomerAddress = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["customer", "mutation", "updateAddress"],
    mutationFn: (payload: UpdateCustomerAddressPayload) => {
      return customerService.updateCustomerAddress(payload);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["customer"],
      });
    },
  });
};
