import {
  useAuthUser,
  useOnUserStateChange,
  useSignInWithCustomToken,
} from "@/hooks/service-hooks/auth/use-auth.ts";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast.ts";
import { useEffect } from "react";
import { RoutePath } from "@/routes/constants/route-paths.ts";

export const useSignInUser = () => {
  useOnUserStateChange();
  const user = useAuthUser();
  const navigate = useNavigate();
  const { toast } = useToast();
  const token = new URLSearchParams(window.location.search).get("token");
  const signInWithCustomToken = useSignInWithCustomToken();

  useEffect(() => {
    if (user) {
      return;
    }

    if (signInWithCustomToken.isPending) {
      return;
    }

    if (!token) {
      return;
    }

    if (token) {
      signInWithCustomToken.mutate(token);
    }
  }, [navigate, signInWithCustomToken, toast, token, user]);

  useEffect(() => {
    if (user) {
      navigate(RoutePath.SUBSCRIPTION);
    }

    if (signInWithCustomToken.isSuccess) {
      navigate(RoutePath.SUBSCRIPTION);
    }
  }, [navigate, signInWithCustomToken.isSuccess, user]);

  if (signInWithCustomToken.isError) {
    navigate(RoutePath.LOGIN);
    toast({
      title: "Error",
      description: "An error occurred",
      variant: "destructive",
    });
  }

  return {
    user,
    isLoading: signInWithCustomToken.isPending,
    error: signInWithCustomToken.isError,
  };
};
