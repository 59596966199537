import { createBrowserRouter } from 'react-router-dom';
import { ErrorPage } from '@/components/shared/error-page.tsx';
import { AppLayout } from '@/components/shared/app-layout.tsx';
import { InternalLayout } from '@/components/shared/internal-layout.tsx';
import { SubscriptionManagePage } from '@/pages/subscription-manage/subscription-manage-page.tsx';
import { SubscriptionOrdersPage } from '@/pages/subscription-orders/subscription-orders-page';
import { SubscriptionProfilePage } from '@/pages/subscription-profile/subscription-profile-page.tsx';
import { SubscriptionPage } from '@/pages/subscription/subscription-page.tsx';
import { RootLayout } from '@/components/shared/root.tsx';
import { RoutePath } from '@/routes/constants/route-paths.ts';
import { LoginPage } from '@/pages/login/login-page';

export const router =  createBrowserRouter([
  {
    path: '/',
    element: <RootLayout/>,
    errorElement: (
      <ErrorPage
        error={{
          statusText: 'Subscription tools not found',
          message: 'The subscription you are looking for does not exist'
        }}
      />
    ),
    children: [
      {},
      {
        path: RoutePath.LOGIN,
        element: <LoginPage/>,
        errorElement: (
          <ErrorPage
            error={{
              statusText: 'Login not found',
              message: 'The login you are looking for does not exist'
            }}
          />
        )
      },
      {
        path: '/app',
        element: <AppLayout/>,
        children: [
          {
            path: 'subscription',
            element: <InternalLayout/>,
            errorElement: (
              <ErrorPage
                error={{
                  statusText: 'Subscription tools not found',
                  message:
                    'The subscription you are looking for does not exist'
                }}
              />
            ),
            children: [
              {
                path: RoutePath.SUBSCRIPTION,
                element: <SubscriptionPage/>,
                errorElement: (
                  <ErrorPage
                    error={{
                      statusText: 'Subscription schedules not found',
                      message:
                        'The subscription you are looking for does not exist'
                    }}
                  />
                )
              },
              {
                path: RoutePath.SUBSCRIPTION_MANAGE,
                element: <SubscriptionManagePage/>,
                errorElement: (
                  <ErrorPage
                    error={{
                      statusText: 'Subscription schedules not found',
                      message:
                        'The subscription you are looking for does not exist'
                    }}
                  />
                )
              },
              {
                path: RoutePath.SUBSCRIPTION_ORDERS,
                element: <SubscriptionOrdersPage/>,
                errorElement: (
                  <ErrorPage
                    error={{
                      statusText: 'Subscription orders not found',
                      message:
                        'The subscription you are looking for does not exist'
                    }}
                  />
                )
              },
              {
                path: RoutePath.SUBSCRIPTION_PROFILE,
                element: <SubscriptionProfilePage/>,
                errorElement: (
                  <ErrorPage
                    error={{
                      statusText: 'Profile not found',
                      message: 'The profile you are looking for does not exist'
                    }}
                  />
                )
              }
            ]
          }
        ]
      }
    ]
  }
]);
