import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import { useUpdateNextDeliveryDate } from "@/hooks";
import { useNextDeliveryForm } from "@/hooks/forms/entities/form/use-next-delivery-form.ts";

import { NextDeliveryDate } from "@/core/entities/forms/next-delivery-date.ts";

import { cn } from "@/lib/utils";

import { Icons } from "../ui/icons";
import { formatLocale } from "@/utils/format/format-locale.ts";

export function NextDeliveryForm({
  defaultValues,
  closeModal,
}: {
  defaultValues: NextDeliveryDate;
  closeModal: () => void;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation("translation");

  const updateNextDeliveryDate = useUpdateNextDeliveryDate();
  const form = useNextDeliveryForm({
    defaultValues: {
      date: defaultValues.date || new Date(),
    },
    mode: "onChange",
  });

  const onSubmit = useCallback(
    (data: NextDeliveryDate) => {
      const today = new Date();
      const nextDeliveryDate = new Date(data.date);

      if (today >= nextDeliveryDate) {
        closeModal();

        toast({
          title: t("error"),
          description: t("nextDeliveryDateIsBeforeToday"),
        });

        return;
      }

      const toDateString = data.date.toDateString();

      updateNextDeliveryDate.mutate({
        nextDeliveryDate: toDateString,
      });
    },
    [closeModal, t, updateNextDeliveryDate],
  );

  useEffect(() => {
    if (updateNextDeliveryDate.isSuccess) {
      toast({
        title: t("success"),
        description: t("nextDeliveryDate"),
      });

      closeModal();
    }

    if (updateNextDeliveryDate.isError) {
      toast({
        title: t("error"),
        description: t("failedToUpdateCustomerData"),
      });
    }
  }, [
    closeModal,
    t,
    updateNextDeliveryDate.isError,
    updateNextDeliveryDate.isSuccess,
  ]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="date"
          render={({ field }) => (
            <FormItem className="flex flex-col w-full">
              <FormLabel>{t("nextDeliveryD")}</FormLabel>
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant={"outline"}
                      className={cn(
                        "w-full pl-3 text-left font-normal",
                        !field.value && "text-muted-foreground",
                      )}
                    >
                      {field.value ? (
                        format(field.value, "PPP", {
                          locale: formatLocale(language),
                        })
                      ) : (
                        <span>Pick a date</span>
                      )}
                      <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    locale={formatLocale(language)}
                    mode="single"
                    selected={field.value}
                    toDate={new Date("2050-01-01")}
                    onSelect={field.onChange}
                    disabled={(date) => date < new Date()}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
              <FormDescription>
                {t("weWillCalculateOnNextDelivery")}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          disabled={updateNextDeliveryDate.isPending}
          className={"w-full"}
          type="submit"
        >
          {updateNextDeliveryDate.isPending && <Icons.loading />}
          {t("update")}
        </Button>
      </form>
    </Form>
  );
}
