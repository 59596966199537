export const RoutePath = {
  SUBSCRIPTION: "/app/subscription/details",
  SUBSCRIPTION_MANAGE: "/app/subscription/manage",
  SUBSCRIPTION_ORDERS: "/app/subscription/orders",
  SUBSCRIPTION_PROFILE: "/app/subscription/profile",
  LOGIN: "/login",
};

export const OuterPath = {
  TEST_PACKAGE_URL: "https://coffydoor.com/products/coffydoor-testpaket",
  HOME_PAGE_URL: 'https://coffydoor.com/'
};
