import {
  initializeApp,
  getApps,
  FirebaseApp,
  FirebaseOptions
} from '@firebase/app';

import { initializeAuth, browserLocalPersistence, Auth } from '@firebase/auth';
import { getDatabase, Database } from '@firebase/database';
import { getFirestore, Firestore } from '@firebase/firestore';
import { getFunctions, Functions } from '@firebase/functions';
import { getStorage, FirebaseStorage } from '@firebase/storage';

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyCooycUwSnaqSLT3m6yC_B0j_gvykXrEow",
  authDomain: "coffydoor-55d2f.firebaseapp.com",
  projectId: "coffydoor-55d2f",
  storageBucket: "coffydoor-55d2f.appspot.com",
  messagingSenderId: "15552948267",
  appId: "1:15552948267:web:1e8daa9bc2ee14080ca26e"
};

const initializeFirebaseApp = (): FirebaseApp => {
  let app: FirebaseApp;

  const apps = getApps();

  if (apps.length === 0) {
    app = initializeApp(firebaseConfig);
  } else {
    app = apps[0];
  }

  return app;
};

const app = initializeFirebaseApp();

export type Firebase = {
  app: FirebaseApp;
  auth: Auth;
  database: Database;
  firestore: Firestore;
  functions: Functions;
  storage: FirebaseStorage;
};

export const firebase: Firebase = {
  app,
  auth: initializeAuth(app, {
    persistence: browserLocalPersistence
  }),
  database: getDatabase(app),
  firestore: getFirestore(app),
  functions: getFunctions(app),
  storage: getStorage(app)
};

export const projectId = firebaseConfig.projectId;
