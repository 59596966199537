import { serviceHost } from "@/services";
import { useMutation } from "@tanstack/react-query";

const klarnaService = serviceHost.getKlarnaService();

/** TODO: Check if we use this hooks or they should be deleted
export const useCustomerPortalOrderForTomorrow = () => {
  return useMutation({
    mutationKey: ['customerPortalOrderForTomorrow'],
    mutationFn: () => klarnaService.customerPortalOrderForTomorrow()
  });
};

export const useCustomerPortalPauseSubscription = () => {
  return useMutation({
    mutationKey: ['customerPortalPauseSubscription'],
    mutationFn: () => klarnaService.customerPortalPauseSubscription()
  });
};

export const useCustomerPortalReactivateSubscription = () => {
  return useMutation({
    mutationKey: ['customerPortalReactivateSubscription'],
    mutationFn: () => klarnaService.customerPortalReactivateSubscription()
  });
};

export const useCustomerPortalUpdateSubscription = () => {
  return useMutation({
    mutationKey: ['customerPortalUpdateSubscription'],
    mutationFn: (payload: CustomerPortalUpdateSubscriptionPayload) => klarnaService.customerPortalUpdateSubscription(payload)
  });
};
 */

export const useChangePaymentMethod = () => {
  return useMutation({
    mutationKey: ["changePaymentMethod"],
    mutationFn: () => klarnaService.customerPortalChangePaymentMethod(),
  });
};
