import { useForm, UseFormProps } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Address, addressSchema } from "@/core";

export function useChangeDeliveryAddressForm(props: UseFormProps<Address>) {
  return useForm<Address>({
    ...props,
    resolver: zodResolver(addressSchema),
    mode: "onChange",
  });
}
