import { FC, useMemo } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card.tsx';
import { Badge } from '@/components/ui/badge.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import { useTranslation } from 'react-i18next';
import { useGetDiscount, useGetSubscription } from '@/hooks';
import { useAuthUser } from '@/hooks/service-hooks/auth/use-auth.ts';
import { LoadingState } from '@/components/shared/LoadingState.tsx';
import { ErrorState } from '@/components/shared/ErrorState.tsx';
import { EmptyState } from '@/components/shared/EmptyState.tsx';
import { formatDate } from '@/utils/format/format-date.ts';
import { CustomerData, subscriptionIntervalToNumber, SubscriptionStatus } from '@/core';
import { ShippingAddress } from '@/components/shared/ShippingAddress.tsx';
import {
  SubscriptionProductSummary
} from '@/pages/subscription/components/next-delivery-card/SubscriptionProductSummary.tsx';
import {
  SubscriptionAdditionalProducts
} from '@/pages/subscription/components/next-delivery-card/AdditionalProducts.tsx';
import { SubscriptionSummary } from '@/pages/subscription/components/next-delivery-card/SubscriptionSummary.tsx';
import { addWeeks } from 'date-fns';

interface NextDeliveryCardProps {
  customer: CustomerData;
}

export const NextDeliveryCard: FC<NextDeliveryCardProps> = ({ customer }) => {
  const { t, i18n } = useTranslation("translation");
  const authUser = useAuthUser();
  const language = i18n.language;
  const {
    data: subscription,
    isLoading: isSubscriptionLoading,
    isError: isSubscriptionError,
  } = useGetSubscription({ id: authUser?.uid || "" });

  const {
    data: discount,
    isLoading: isDiscountCodeLoading,
    isError: isDiscountCodeError,
  } = useGetDiscount({ id: subscription?.discountCode || '' });


  const nextBillingDateWhenPaused = useMemo(() => {
    if (!subscription) {
      return;
    }

    const initialDate = new Date(subscription.nextBillingDateAsString);
    const weeksToAdd = subscriptionIntervalToNumber[subscription.interval];

    return addWeeks(initialDate, weeksToAdd);
  }, [subscription]);

  const selectBadgeVariant = useMemo(() => {
    if (!subscription) {
      return 'default';
    }

    if (subscription.status === SubscriptionStatus.SKIPPED) {
      return 'default';
    }

    if (subscription.status === SubscriptionStatus.CANCELLED) {
      return 'destructive';
    }

    if (subscription.status === SubscriptionStatus.ACTIVE) {
      return 'bill';
    }

    return 'default';
  }, [subscription]);
  if (!subscription || !customer) {
    return (
      <EmptyState
        title={t("noSubscriptionFound")}
        message={t("noSubscriptionFoundMessage")}
      />
    );
  }
  if (isSubscriptionLoading) {
    return <LoadingState />;
  }

  if (isSubscriptionError) {
    return <ErrorState />;
  }

  return (
    <Card className={"w-full"}>
      <CardHeader className={"flex justify-between items-center flex-row"}>
        <div className={"space-y-1.5"}>
          <CardTitle>{t("nextDelivery")}</CardTitle>
          <CardDescription>
            {t("yourNextDelivery")}
            <span className={"pl-1 font-bold"}>
              {subscription.status === SubscriptionStatus.SKIPPED && formatDate(nextBillingDateWhenPaused as Date, language)}
              {subscription.status !== SubscriptionStatus.SKIPPED && formatDate(subscription.nextBillingDate, language)}
            </span>
          </CardDescription>
        </div>

        <div>
          <Badge variant={selectBadgeVariant}>{t(subscription.status)}</Badge>
        </div>
      </CardHeader>

      <CardContent className={"space-y-6"}>
        <SubscriptionProductSummary subscription={subscription}  discount={discount}/>

        <Separator />
      </CardContent>

      {subscription.additionalProducts.length > 0 && (
        <SubscriptionAdditionalProducts subscription={subscription} />
      )}

      <CardHeader>
        <CardTitle className={"font-semibold"}>
          {t("shippingAddress")}
        </CardTitle>
        <CardDescription className={"font-extralight"}></CardDescription>
      </CardHeader>

      <CardContent>
        <ShippingAddress address={customer.shippingAddress} />
        <Separator />
      </CardContent>

      <CardHeader>
        <CardTitle className={"font-semibold"}>
          {t("summary")}
        </CardTitle>
      </CardHeader>

      <CardContent>
        <SubscriptionSummary
          subscription={subscription}
          discount={discount}
          isDiscountCodeLoading={isDiscountCodeLoading}
          isDiscountCodeError={isDiscountCodeError}
        />
      </CardContent>
    </Card>
  );
};
