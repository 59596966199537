import { useTranslation } from 'react-i18next';
import { FC, useCallback, useEffect } from 'react';

import { Icons } from '@/components/ui/icons';
import { Badge } from '@/components/ui/badge.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useToast } from '@/components/ui/use-toast.ts';
import { DiscountData } from '@/core/entities/forms/discout.ts';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card.tsx';
import { Form, FormControl, FormDescription, FormField, FormItem, FormMessage } from '@/components/ui/form.tsx';

import { useApplyDiscountCode, useRemoveDiscountCode } from '@/hooks';
import { useDiscountForm } from '@/hooks/forms/entities/form/use-discount-form.ts';

import { Subscription, SubscriptionStatus } from '@/core';

interface DiscountCodeProps {
  subscription: Subscription;
}

export const DiscountCode: FC<DiscountCodeProps> = ({
  subscription
}) => {
  const { t } = useTranslation("translation");
  const { toast } = useToast();
  const form = useDiscountForm({
    defaultValues: {
      code: "",
    },
    mode: "onChange",
  });

  const applyDiscountCode = useApplyDiscountCode();
  const removeDiscountCode = useRemoveDiscountCode();

  const onApplySubmit = useCallback(
    (values: DiscountData) => {
      applyDiscountCode.mutate({
        code: values.code,
      });
    },
    [applyDiscountCode],
  );
  const onRemoveDiscountCode = useCallback(() => {
    removeDiscountCode.mutate();
  }, [removeDiscountCode]);

  useEffect(() => {
    if (applyDiscountCode.isSuccess) {
      toast({
        title: t("discountCodeApplied"),
        description: t("discountCodeAppliedDescription"),
      });
    }
  }, [applyDiscountCode.isSuccess, t, toast]);

  useEffect(() => {
    if (applyDiscountCode.isError) {
      toast({
        title: t("error"),
        description: t("discountCodeError"),
      });
    }
  }, [applyDiscountCode.isError, t, toast]);


  useEffect(() => {
    if (removeDiscountCode.isSuccess) {
      toast({
        title: t("discountCodeRemoved"),
      });
    }
  }, [removeDiscountCode.isSuccess, t, toast]);

  useEffect(() => {
    if (removeDiscountCode.isError) {
      toast({
        title: t("error"),
        description: t("discountCodeError"),
      });
    }
  }, [removeDiscountCode.isError, t, toast]);

  return (
    <Card className={"flex flex-col justify-between"}>
      <CardHeader>
        <CardTitle className={"text-2xl"}>
          {t("discountCode")}
        </CardTitle>
        <CardDescription className={"font-light"}>
          {t("discountCodeDescription")}
        </CardDescription>
        {subscription.discountCode && (
          <CardTitle>
            <Badge>{subscription.discountCode}</Badge>
          </CardTitle>
        )}
      </CardHeader>
      <CardContent>
        <div className={""}>
          {!subscription.discountCode && (
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onApplySubmit)} className="space-y-8">
                <FormField
                  control={form.control}
                  name="code"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input placeholder={t('discountCode')} {...field} />
                      </FormControl>
                      <FormDescription>
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className={"flex justify-center"}>
                  <Button
                    disabled={
                      form.watch('code') === '' ||
                      applyDiscountCode.isPending ||
                      subscription.status === SubscriptionStatus.CANCELLED ||
                      subscription.status === SubscriptionStatus.FAILED
                    }
                    type="submit"
                  >
                    {applyDiscountCode.isPending && <Icons.loading />}
                    {t('apply')}
                  </Button>
                </div>
              </form>
            </Form>
          )}

          {subscription.discountCode && (
            <Button
              variant={"destructive"}
              onClick={onRemoveDiscountCode}
              disabled={
                removeDiscountCode.isPending ||
                subscription.status === SubscriptionStatus.CANCELLED ||
                subscription.status === SubscriptionStatus.FAILED
              }
            >
              {removeDiscountCode.isPending && <Icons.loading/>}
              {t('remove')}
            </Button>
          )}
        </div>
      </CardContent>
    </Card>
  );
};
