import { httpsCallable } from "@firebase/functions";
import { firebase } from "@/infrastructure";
import {
  CustomerService,
  UpdateCustomerAddressPayload,
  UpdateCustomerPayload,
} from "@/core";

export const customerService: CustomerService = {
  async updateCustomerProfile(payload) {
    await httpsCallable<UpdateCustomerPayload, void>(
      firebase.functions,
      "v2CustomerPortalUpdateCustomerProfile",
    )(payload);
  },
  async updateCustomerAddress(payload) {
    await httpsCallable<UpdateCustomerAddressPayload, void>(
      firebase.functions,
      "v2CustomerPortalUpdateCustomerAddress",
    )(payload);
  },
};
