import { useAuthUser } from "@/hooks/service-hooks/auth/use-auth.ts";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "@/routes/constants/route-paths.ts";

export const useProtectedPage = () => {
  const authUser = useAuthUser();
  const navigate = useNavigate();

  if (!authUser?.uid) {
    navigate(RoutePath.LOGIN);
  }
};
