import * as z from "zod";

import { baseEntitySchema } from "./base";
import { Money, moneySchema } from "./utils/money";

export const REFERRAL_AMOUNT: Money = {
  amount: 100,
  currency: "SEK",
};

// id will be the same as the customerID mapped 1:1
export const customerReferralDataSchema = z.object({
  referralAmount: moneySchema,
  referredCustomers: z.array(
    z.object({
      customerID: z.string(),
      orderID: z.string(),
      date: z.date(),
      referralAmount: moneySchema,
    }),
  ),

  // TODO: handle give and receive referrals
});

export type CustomerReferralData = z.infer<typeof customerReferralDataSchema>;

export const customerReferralSchema = baseEntitySchema.merge(
  customerReferralDataSchema,
);

export type CustomerReferral = z.infer<typeof customerReferralSchema>;
