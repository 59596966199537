import { CustomerReferral, CustomerReferralData, CustomerReferralRepository, DatabaseService } from '@/core';

import { DatabaseCollection } from '@/repositories/config.ts';

export function getCustomerReferral (database: DatabaseService): CustomerReferralRepository {
  return {
    async getCustomerReferral ({ id }) {
      const result = await database.get<CustomerReferral>(DatabaseCollection.CUSTOMER_REFERRALS, id);

      if (!result) {
        throw new Error(`Customer referral not found for customer ${id}`);
      }

      return result;
    },
    updateCustomerReferral ({ id, data }) {
      //TODO: Check if this is the correct way to update the referral data
      return database.update<CustomerReferralData>(DatabaseCollection.CUSTOMER_REFERRALS, id, data);
    },
    // TEST FUNCTIONALITY: DEVELOPMENT ONLY
    async setCustomerReferral ({ id, data }) {
      return database.set(DatabaseCollection.CUSTOMER_REFERRALS, id, data);
    }
  };
}
