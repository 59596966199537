import { FC } from "react";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";

import { UpdateNextBillingDate } from "@/pages/subscription-manage/components/update-next-billing-date.tsx";

import { SubscriptionData } from "@/core";

interface DeliveryScheduleProps {
  subscriptionData: SubscriptionData;
}

export const NextBillingDate: FC<DeliveryScheduleProps> = (props) => {
  const { subscriptionData } = props;
  const { t } = useTranslation("translation");

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("nextBillingDate")}</CardTitle>
      </CardHeader>

      <CardFooter className={"flex gap-4 h-24 items-end justify-between"}>
        <UpdateNextBillingDate
          subscription={subscriptionData}
          currentDeliveryDate={subscriptionData.nextBillingDate}
        />
      </CardFooter>
    </Card>
  );
};
