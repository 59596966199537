export const formatDateString = (date: string, locale: string) => {
  // Locale code is sv for Swedish and en for English, country code is SE for Sweden and GB for Great Britain
  return new Date(date).toLocaleDateString(locale === "en" ? "en" : "sv", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
};

export const formatDate = (date: Date, locale: string) => {
  // Locale code is sv for Swedish and en for English, country code is SE for Sweden and GB for Great Britain
  return date.toLocaleDateString(locale === "en" ? "en" : "sv", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
};
