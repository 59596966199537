import {
  DatabaseService,
  KlarnaService,
  ServiceHost,
  DiscountCodeService,
  AuthenticationService, CustomerService
} from '@/core';

import { databaseService } from "@/services/database/database-service.ts";
import { klarnaService } from "@/services/klarna/klarna-service.ts";
import { discountCodeService } from "@/services/discount-code/discount-code-service.ts";
import { subscriptionService } from "@/services/subscription/subscription-service.ts";
import { SubscriptionService } from "@/core/ports/services/subscription-service.ts";
import { authenticationService } from "@/services/authentication/authentication-service.ts";
import { customerService } from '@/services/customer/customer-service.ts';

/**
 * Service host
 *
 * @export
 * @interface ServiceHost
 */
export const serviceHost: ServiceHost = {
  getAuthenticationService(): AuthenticationService {
    return authenticationService;
  },
  getDatabaseService(): DatabaseService {
    return databaseService;
  },
  getKlarnaService(): KlarnaService {
    return klarnaService;
  },
  getDiscountCodeService(): DiscountCodeService {
    return discountCodeService;
  },
  getSubscriptionService(): SubscriptionService {
    return subscriptionService;
  },
  getCustomerService(): CustomerService{
    return customerService;
  }
};
