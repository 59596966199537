import { CustomerPoints, CustomerPointsData, CustomerPointsRepository, DatabaseService } from '@/core';
import { DatabaseCollection } from '@/repositories/config.ts';


export function getCustomerPointsRepository (database: DatabaseService): CustomerPointsRepository {
  return {
    async getCustomerPoints ({ id }) {
      const result = await database.get<CustomerPoints>(DatabaseCollection.CUSTOMER_POINTS, id);

      if (!result) {
        throw new Error(`Customer points not found for customer ${id}`);
      }

      return result;
    },
    updateCustomerPoints ({ id, data }) {
      return database.update<CustomerPointsData>(DatabaseCollection.CUSTOMER_POINTS, id, data);
    },
    // TEST FUNCTIONALITY: DEVELOPMENT ONLY
    async setCustomerPoints ({ id, data }) {
      return database.set<Partial<CustomerPointsData>>(DatabaseCollection.CUSTOMER_POINTS, id, data);
    }
  };
}
