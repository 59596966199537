import { FC } from "react";
import {
  useAuthUser,
  useSignOut,
} from "@/hooks/service-hooks/auth/use-auth.ts";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Icons } from "@/components/ui/icons.tsx";
import { useTranslation } from 'react-i18next';

export const ProfileDropdown: FC = () => {
  const authUser = useAuthUser();
  const { t } = useTranslation("translation");

  const signOut = useSignOut();

  if (!authUser) {
    return null;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Button variant={"ghost"} size={"icon"}>
          <Icons.profile />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align={"end"}>
        <DropdownMenuLabel>{authUser.email}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={() => {
            signOut.mutate();
          }}
        >
          <Icons.logout className={"mr-2 h-4 w-4"} />
          {t('logOut')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
