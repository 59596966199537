import { RepositoryHost } from "@/core";
import { getSubscriptionRepository } from "@/repositories/subscription-repository.ts";
import { getOrderHistoryRepository } from "@/repositories/order-history.ts";
import { getCustomerRepository } from "@/repositories/customer-repository.ts";
import { getCustomerPointsRepository } from "@/repositories/customer-points-repository.ts";
import { getCustomerReferral } from "@/repositories/customer-referral-repository.ts";
import { getCustomerResendRepository } from "@/repositories/customer-resend-repository.ts";
import { getDiscountCodeRepository } from "@/repositories/discount-code-repository.ts";
import { getDeliveryAddressRepository } from "@/repositories/delivery-address-repository.ts";

export const repositoryHost: RepositoryHost = {
  getSubscriptionRepository(databaseService) {
    return getSubscriptionRepository(databaseService);
  },
  getCustomerRepository(databaseService) {
    return getCustomerRepository(databaseService);
  },
  getOrderHistoryRepository(databaseService) {
    return getOrderHistoryRepository(databaseService);
  },
  getCustomerPointsRepository(databaseService) {
    return getCustomerPointsRepository(databaseService);
  },
  getCustomerReferralRepository(databaseService) {
    return getCustomerReferral(databaseService);
  },
  getCustomerResendRepository(databaseService) {
    return getCustomerResendRepository(databaseService);
  },
  getDiscountCodeRepository(databaseService) {
    return getDiscountCodeRepository(databaseService);
  },
  getDeliveryAddressRepository(databaseService) {
    return getDeliveryAddressRepository(databaseService);
  },
};
