import { FC } from "react";
import { Icons } from "../ui/icons";

interface ErrorStateProps {
  message?: string;
  title?: string;
}
export const ErrorState: FC<ErrorStateProps> = ({
  title = "Error loading data",
  message = " Please try again later or contact support if the problem persists.",
}) => {
  return (
    <div className={"w-full flex flex-col justify-center items-center h-96"}>
      <Icons.error className="h-8 w-8 text-destructive fill-destructive-foreground" />
      <div className={"text-foreground text-center text-lg font-semibold"}>
        {title}
        <br />
        <p
          className={
            "text-muted-foreground text-center text-base font-normal mt-4 max-w-sm"
          }
        >
          {message}
        </p>
      </div>
    </div>
  );
};
