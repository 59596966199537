import { FC, HTMLAttributes, useCallback } from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { Icons } from "@/components/ui/icons.tsx";
import { useTranslation } from "react-i18next";
import { DestructiveAlert } from "@/components/shared/DestructiveAlert.tsx";
import { useAuthUser, useChangePaymentMethod } from "@/hooks";

interface PaymentMethodProps extends HTMLAttributes<HTMLDivElement> {
  lastFourDigitsCode: string;
  expiresDate: string;
}

export const PaymentMethod: FC<PaymentMethodProps> = (props) => {
  const { t } = useTranslation("translation");
  const { lastFourDigitsCode, expiresDate } = props;
  const authUser = useAuthUser();
  const changePaymentMethod = useChangePaymentMethod();

  const onChangePaymentMethod = useCallback(async () => {
    if (!authUser) {
      console.log("user not authenticated");

      return;
    }

    window.location.href = await changePaymentMethod.mutateAsync();
  }, [authUser, changePaymentMethod]);

  return (
    <Card>
      <CardHeader className={"text-2xl font-semibold"}>
        <CardTitle>{t("paymentMethod")}</CardTitle>
      </CardHeader>

      <CardContent>
        <div className={"sm:flex sm:justify-between items-center"}>
          <div className={"flex w-full justify-between"}>
            <div className={"w-16 h-16"}>
              <Icons.masterCard />
            </div>

            <div className={"ml-6 w-fit"}>
              <p className={" mt-2 font-bold"}>**** {lastFourDigitsCode}</p>
              <p className={"font-light mt-2"}>Expires {expiresDate}</p>
            </div>
          </div>
        </div>
      </CardContent>

      <CardFooter>
        {/*<Button*/}
        {/*  onClick={() => alert("Change payment method")}*/}
        {/*  variant={"outline"}*/}
        {/*  className={"w-full"}*/}
        {/*>*/}
        {/*  {t("changePaymentMethod")}*/}
        {/*</Button>*/}

        <DestructiveAlert
          isDropdown={false}
          isActive={true}
          confirmActionLoading={changePaymentMethod.isPending}
          title={t("changePaymentMethod")}
          description={t("changePaymentMethodMessage")}
          triggerText={t("changePaymentMethod")}
          cancelText={t("cancel")}
          confirmAction={onChangePaymentMethod}
          confirmText={t("change")}
        />
      </CardFooter>
    </Card>
  );
};
