import { FC, HTMLAttributes } from "react";
import { Icons } from "@/components/ui/icons.tsx";
import { cn } from "@/lib/utils.ts";
import { SidebarNavMobile } from "@/components/shared/sidebar-nav-mobile.tsx";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button.tsx";
import { ProfileDropdown } from "@/components/shared/ProfileDropdown.tsx";
import { RoutePath } from "@/routes/constants/route-paths.ts";
import { useTranslation } from "react-i18next";

interface HeaderMobileProps extends HTMLAttributes<HTMLDivElement> {}

export interface NavigationItem {
  href: string;
  label: string;
  icon: JSX.Element;
}

export const HeaderMobile: FC<HeaderMobileProps> = (props) => {
  const { className } = props;
  const auth = true;
  const { t } = useTranslation("translation");

  return (
    <div className={cn(className, "flex justify-between items-center ")}>
      <div className={""}>
        <SidebarNavMobile
          trigger={<Icons.menuIcon />}
          items={[
            {
              label: t("subscription"),
              href: RoutePath.SUBSCRIPTION,
              icon: <Icons.subscription />,
            },
            {
              label: t("manageSubscription"),
              href: RoutePath.SUBSCRIPTION_MANAGE,
              icon: <Icons.manageSubscription />,
            },
            {
              label: t("orderHistory"),
              href: RoutePath.SUBSCRIPTION_ORDERS,
              icon: <Icons.orderHistory />,
            },
            {
              label: t("myProfile"),
              href: RoutePath.SUBSCRIPTION_PROFILE,
              icon: <Icons.profile />,
            },
          ]}
        />
      </div>
      <div className={""}>
        <Link to={"/"}>
          <Icons.logo />
        </Link>
      </div>
      <div className={""}>
        <ProfileDropdown />
        {!auth && (
          <Button variant={"ghost"} asChild>
            <Link to={"/login"}>Login</Link>
          </Button>
        )}
      </div>
    </div>
  );
};
