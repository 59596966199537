import { NextDeliveryCard } from '@/pages/subscription/components/next-delivery-card/next-delivery-card.tsx';
import { useTranslation } from 'react-i18next';
import { useAuthUser, useGetCustomer, useGetSubscription } from '@/hooks';
import { LoadingState } from '@/components/shared/LoadingState.tsx';
import { ErrorState } from '@/components/shared/ErrorState.tsx';
import { EmptyState } from '@/components/shared/EmptyState.tsx';
import { AdditionalProducts } from '@/pages/subscription/components/AdditionalProducts.tsx';
import { useProtectedPage } from '@/hooks/use-protect-page.ts';
import { Link } from 'react-router-dom';
import { OuterPath } from '@/routes/constants/route-paths.ts';
import { Button } from '@/components/ui/button.tsx';

export function SubscriptionPage () {
  const { t } = useTranslation("translation");
  const authUser = useAuthUser();

  useProtectedPage();

  const {
    data: customer,
    isLoading: isCustomerLoading,
    isError: isCustomerError,
  } = useGetCustomer({ id: authUser?.uid || '' });

  const {
    data: subscription,
    isLoading: isSubscriptionLoading,
    isError: isSubscriptionError
  } = useGetSubscription({ id: authUser?.uid || '' });

  if (!subscription || isSubscriptionLoading) {
    return <LoadingState/>;
  }

  if (isSubscriptionError) {
    return <ErrorState/>;
  }

  if (!subscription) {
    return (
      <div className={'flex justify-center'}>
        <Link to={OuterPath.TEST_PACKAGE_URL} target={'_blank'}>
          <Button
          >
            {t('subscribe')}
          </Button>
        </Link>
      </div>
    );
  }

  if (isCustomerLoading || !customer) {
    return <LoadingState />;
  }

  if (isCustomerError) {
    return (
      <ErrorState
        title={t("customerError")}
        message={t("customerErrorMessage")}
      />
    );
  }

  if (!customer) {
    return (
      <EmptyState
        title={t("noCustomerData")}
        message={t("noCustomerDataMessage")}
      />
    );
  }

  return (
    <div  className={'lg:pl-4 sm:py-12 lg:py-0'}>
      <div className={'grid mb-4'}>
        <h1 className={'text-4xl font-light'}>
          {t('subscription')}
        </h1>
        <h3 className={'mt-3 font-extralight'}>
          {t('seeWhenIsYourNextDelivery')}
        </h3>
      </div>

      <div
        className={'flex justify-between w-full lg:gap-12 flex-col lg:flex-row'}
      >
        <NextDeliveryCard customer={customer}/>

        <AdditionalProducts/>
      </div>
    </div>
  );
}
