import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import { FC, useCallback, useEffect } from "react";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input.tsx";
import { useToast } from "@/components/ui/use-toast.ts";

import { useUpdateCustomerProfile } from "@/hooks";
import { useAuthUser } from "@/hooks/service-hooks/auth/use-auth.ts";
import { useProfileForm } from "@/hooks/forms/entities/form/use-profile-form.ts";

import { CustomerProfileData } from "@/core";

import { Icons } from "../ui/icons";

import "react-phone-input-2/lib/style.css";

interface UpdateProfileFormProps {
  defaultValues: CustomerProfileData;
  closeDialog: () => void;
}

export const CustomerProfileForm: FC<UpdateProfileFormProps> = ({
  defaultValues,
  closeDialog,
}) => {
  const authUser = useAuthUser();
  const { toast } = useToast();
  const form = useProfileForm({
    defaultValues: defaultValues,
  });

  const { t } = useTranslation("translation");

  const updateCustomerProfile = useUpdateCustomerProfile();

  const onSubmit = useCallback(
    (values: CustomerProfileData) => {
      if (!authUser) {
        return;
      }

      updateCustomerProfile.mutate({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone || "",
      });
    },
    [authUser, updateCustomerProfile],
  );

  useEffect(() => {
    if (updateCustomerProfile.isSuccess) {
      toast({
        title: t("customerUpdated"),
      });
      closeDialog();
    }
  }, [closeDialog, t, toast, updateCustomerProfile.isSuccess]);

  useEffect(() => {
    if (updateCustomerProfile.isError && updateCustomerProfile.error) {
      if (
        updateCustomerProfile.error.message
          .toLowerCase()
          .includes("email has already been taken")
      ) {
        toast({
          title: t("customerUpdateFailedEmailAlreadyTaken"),
          variant: "destructive",
        });
      } else if (
        updateCustomerProfile.error.message
          .toLowerCase()
          .includes("phone number has already been taken")
      ) {
        toast({
          title: t("customerUpdateFailedPhoneAlreadyTaken"),
          variant: "destructive",
        });
      } else {
        toast({
          title: t("customerUpdateFailed"),
          variant: "destructive",
        });
      }

      closeDialog();
    }
  }, [
    closeDialog,
    t,
    toast,
    updateCustomerProfile.error,
    updateCustomerProfile.isError,
  ]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="firstName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("firstName")}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormDescription>
                {/*This is your public display name.*/}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="lastName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("lastName")}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormDescription>
                {/*This is your public display name.*/}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("phone")}</FormLabel>
              <FormControl>
                <PhoneInput
                  inputStyle={{ width: "100%" }}
                  country={"se"}
                  {...field}
                  onChange={(value) => {
                    // add + to the phone number
                    field.onChange(value.startsWith("+") ? value : `+${value}`);
                    console.log("value", field.value);
                  }}
                  // onChange={field.onChange}
                  autoFormat={false}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("email")}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormDescription>
                {/*This is your public display name.*/}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className={"flex justify-center"}>
          <Button
            disabled={updateCustomerProfile.isPending}
            type="submit"
            className={"w-full"}
          >
            {updateCustomerProfile.isPending ? <Icons.loading /> : t("save")}
          </Button>
        </div>
      </form>
    </Form>
  );
};
