import * as z from 'zod';

import { baseEntitySchema } from './base';
import { moneySchema } from './utils/money';

// TODO Check the path it's exported in index.ts

export enum OrderStatus {
  AUTHORIZED = "AUTHORIZED",
  PART_CAPTURED = "PART_CAPTURED",
  CAPTURED = "CAPTURED",
  CANCELLED = "CANCELLED",
  EXPIRED = "EXPIRED",
  CLOSED = "CLOSED"
}

export const orderLineItemSchema = z.object({
  productID: z.string(),
  variantID: z.string(),
  quantity: z.number().int().min(1),
  price: moneySchema,
  name: z.string(),
  images: z.array(z.string())
});

export type OrderLineItemData = z.infer<typeof orderLineItemSchema>;

export const orderDataSchema = z.object({
  // TODO What is customerID, we refer to customer collection id or it's for shopify customer id?
  customerID: z.number(), // TODO Check if it's a string
  klarnaOrderID: z.string(),
  status: z.nativeEnum(OrderStatus),
  lineItems: z.array(orderLineItemSchema),
  tax: moneySchema,
  totalPrice: moneySchema,
  discount: moneySchema.optional(),
  discountCode: z.string().optional(),
});

export type OrderData = z.infer<typeof orderDataSchema>;

export const orderSchema = baseEntitySchema.merge(orderDataSchema);

export type Order = z.infer<typeof orderSchema>;
