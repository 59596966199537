import { DatabaseService, DiscountCodeRepository } from '@/core';

import { DatabaseCollection } from '@/repositories/config.ts';


export function getDiscountCodeRepository (database: DatabaseService): DiscountCodeRepository {
  return {
    getDiscountCode (payload) {
      return database.getByField(DatabaseCollection.DISCOUNT_CODES, [
        {
          field: 'codeToLowerCase',
          operator: '==',
          value: payload.id.toLowerCase(),
        }
      ]);
    },
    updateDiscountCode (payload) {
      return database.update(DatabaseCollection.DISCOUNT_CODES, payload.id, payload.data);
    },
    // TEST FUNCTIONALITY: DEVELOPMENT ONLY
    setDiscountCode (payload) {
      return database.set(DatabaseCollection.DISCOUNT_CODES, payload.id, payload.data);
    },
  };
}
