import { serviceHost } from '@/services';
import { repositoryHost } from '@/repositories';
import { useMutation, useQuery } from '@tanstack/react-query';
import { IDOnlyPayload, UpdatePayload } from '@/core/ports/repositories/utilities.ts';
import { CustomerPointsData } from '@/core';

const databaseService = serviceHost.getDatabaseService();

const customerPointsRepository = repositoryHost.getCustomerPointsRepository(databaseService);

export const useGetCustomerPoints = (payload: IDOnlyPayload) => {
  return useQuery({
    queryKey: ['customerPoints', 'query', 'get'],
    queryFn: () => {
      return customerPointsRepository.getCustomerPoints(payload);
    }
  });
};

export const useUpdateCustomerPoints = () => {
  return useMutation({
    mutationKey: ['customerPoints', 'mutation', 'update'],
    mutationFn: (payload: UpdatePayload<CustomerPointsData>) => {
      return customerPointsRepository.updateCustomerPoints(payload);
    }
  });
};

// TEST FUNCTIONALITY: DEVELOPMENT ONLY
export const useSetCustomerPoints = () => {
  return useMutation({
    mutationKey: ['customerPoints', 'mutation', 'set'],
    mutationFn: (payload: UpdatePayload<CustomerPointsData>) => {
      return customerPointsRepository.setCustomerPoints(payload);
    }
  });
};

