import { CustomerResendData, CustomerResendRepository, DatabaseService } from '@/core';
import { DatabaseCollection } from '@/repositories/config.ts';


export function getCustomerResendRepository(database:DatabaseService): CustomerResendRepository{
  return {
    getCustomerResend ({ id }): Promise<string | null> {
      return database.get<string>(DatabaseCollection.CUSTOMER_RESEND, id);
    },
    updateCustomerResend ({ id, data }): Promise<void> {
      return database.update<CustomerResendData>(DatabaseCollection.CUSTOMER_RESEND, id, data);
    },
    // TEST FUNCTIONALITY: DEVELOPMENT ONLY
    setCustomerResend (payload) {
      return database.set(DatabaseCollection.CUSTOMER_RESEND, payload.id, payload.data);
    },
  }
}
