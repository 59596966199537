import { useTranslation } from 'react-i18next';
import { FC, useCallback, useEffect } from 'react';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog.tsx';
import { Icons } from '@/components/ui/icons.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useToast } from '@/components/ui/use-toast.ts';

import { useUnSkipSubscription } from '@/hooks';

import { Subscription } from '@/core';

interface UnpauseSubscriptionProps {
  subscription: Subscription;
}

export const UnskipSubscription: FC<UnpauseSubscriptionProps> = () => {
  const { t } = useTranslation("translation");
  const { toast } = useToast();

  const unSkipSubscription = useUnSkipSubscription();

  const onSubmit = useCallback(() => {
    unSkipSubscription.mutate();
  }, [unSkipSubscription]);

  useEffect(() => {
    if (unSkipSubscription.isSuccess) {
      toast({
        title: t("subscriptionUnpaused"),
        description: t("subscriptionUnpausedDescription"),
      });
    }
  }, [unSkipSubscription.isSuccess, t, toast]);

  useEffect(() => {
    if (unSkipSubscription.isError) {
      toast({
        title: t("error"),
        description: t("anErrorOccurred"),
      });
    }
  }, [unSkipSubscription.isError, t, toast]);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant={"active"}>
          {t("unpauseSubscription")}
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("unpauseSubscription")}</DialogTitle>
          <DialogDescription className={"max-w-sm"}>
            {t("areYouSureYouWantToUnPause")}
          </DialogDescription>
        </DialogHeader>


        <Button
          onClick={onSubmit}
          disabled={unSkipSubscription.isPending}
          className={'mx-auto mt-10'}
        >
          {unSkipSubscription.isPending && <Icons.loading/>}
          {t("unpause")}
        </Button>
      </DialogContent>
    </Dialog>
  );
};
