import * as z from "zod";

import { baseEntitySchema } from "./base";

export const addressSchema = z.object({
  address: z.string(),
  address2: z.string().optional(),
  city: z.string(),
  postalCode: z.string(),
  country: z.string(),
  phone: z
    .string()
    .min(5, {
      message: "Phone number is too short",
    })
    // .regex(/^\+46\s?\d{9}$/, {
    //   message: "Invalid phone number",
    // })
    .optional(),
  company: z.string().optional(),
});

export type Address = z.infer<typeof addressSchema>;
export const customerProfileDataSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  // ^\+[1-9]\d{1,14}$ is a regex pattern for phone number validation
  phone: z.string()
    .min(5, {
      message: "Phone number is too short",
    })
    // .regex(/^\+[1-9]\d{1,14}$/, {
    //   message: "Invalid phone number",
    // })
    .optional(),
});

export type CustomerProfileData = z.infer<typeof customerProfileDataSchema>;

// id will be generated by firebase as a unique identifier UID
export const customerDataSchema = z
  .object({
    billingAddress: addressSchema,
    shippingAddress: addressSchema,
    card: z.object({
      maskedCardNumber: z.string(),
      expirationDate: z.string(),
    }),
    paymentToken: z.string(),
  })
  .merge(customerProfileDataSchema);

export type CustomerData = z.infer<typeof customerDataSchema>;

export const customerSchema = baseEntitySchema.merge(customerDataSchema);

export type Customer = z.infer<typeof customerSchema>;
