import { FC, HTMLAttributes } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import { CardDescription, CardTitle } from "@/components/ui/card.tsx";
import { AspectRatio } from "@/components/ui/aspect-ratio.tsx";
import { Icons } from "@/components/ui/icons.tsx";
import { Badge } from "@/components/ui/badge.tsx";
import { useTranslation } from "react-i18next";
import { formatPrice } from "@/utils/format/format-price.ts";

interface AddProductToNextOrderProps extends HTMLAttributes<HTMLDivElement> {
  triggerButtonLabel: string;
  mainLabel: string;
  mainButtonAction: () => void;
  mainButtonLabel: string;
  avatar: string;
  label: string;
  price: number;
  starsPrice: number;
}

export const AddProductToNextOrder: FC<AddProductToNextOrderProps> = (
  props,
) => {
  const { triggerButtonLabel, mainLabel, avatar, label, price, starsPrice } =
    props;

  const { t } = useTranslation("translation");

  const myPoints = 75;

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant={"outline"} size={"sm"}>
          {triggerButtonLabel}
        </Button>
      </DialogTrigger>
      <DialogContent className={"w-full"}>
        <DialogHeader>
          <DialogTitle className={"flex justify-center"}>
            {mainLabel}
          </DialogTitle>
          <DialogDescription
            className={"flex justify-center pt-8"}
          ></DialogDescription>
        </DialogHeader>
        <div className="flex flex-col sm:flex-row items-center justify-between space-x-4">
          <div className="flex items-center space-x-4">
            <div className="w-20">
              <AspectRatio ratio={16 / 9}>
                <img
                  src={avatar}
                  alt="Image"
                  className="rounded-sm object-cover"
                />
              </AspectRatio>
            </div>

            <div className={""}>
              <CardTitle className={"text-base"}>{label}</CardTitle>
              <CardDescription className={"flex items-center"}>
                {formatPrice(price)} kr | {starsPrice}{" "}
                <Icons.star className={"w-4 h-4 ml-1"} />
              </CardDescription>
            </div>
          </div>
          <div className={"justify-center hidden sm:flex"}>
            <Badge variant={"outline"}>{t("oneTime")}</Badge>
          </div>
        </div>

        <div className={"justify-center flex items-center sm:hidden"}>
          <Badge variant={"outline"}>One time</Badge>
        </div>

        <div className={"flex flex-col flex-wrap justify-between gap-4"}>
          <Button variant="outline">{t("buyWithCard")}</Button>

          <Button
            disabled={myPoints < starsPrice}
            variant="outline"
            className={"uppercase"}
          >
            {myPoints < starsPrice
              ? `${t("youAre")} ${starsPrice - myPoints} ${t("pointsAway")}`
              : t("usePoints")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
